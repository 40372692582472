/* eslint-disable eqeqeq */
/* eslint-disable no-loop-func */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import { IInputSmeta, smeta_enum } from "../types/smeta_tables";

export const AsGrandXML =  (source: IInputSmeta) => {

  const GetValue = (source: any[][], row: number, col: number, defval = ""): string => {
    try {
      const result = source[row][col];
      if (result===0) return result;
      if (result) return String(result).replace("<","&lt;").replace(">","&gt;");
    } catch {}
    return defval;
  };
  const GetAttr = (title: string, value: string) => {
    if ((value)||(value=='0')) return ` ${title}="${value}"`
    return ''
  }

  const GetNode=(title:string, offset:number, attrs:string[]=[], childs:string[]=[])=>{
    if ((attrs.length==0) && (childs.length==0)) return ''
    const rep=' '.repeat(offset)
    const begin=`${rep}<${title}`
    let result =begin
    attrs.forEach((item)=>{
      result=`${result}${item}` 
    })
    if ((result==begin) &&  (childs.findIndex(item=>(item))==-1)) return ''
    if (childs.findIndex(item=>(item))==-1) return `${result}/>`
    else result=`${result}>` 
  childs.forEach((Item)=>{
    if (Item) result=`${result}
    ${Item}`
  })
  result=`${result}
  ${' '.repeat(offset+2)}</${title}>`
  return result
}
const GetGlava=(id:string)=>{
  try{return GetNode("AddZatrGlava",0,[GetAttr("Glava", id)],(()=>{
    let result=[]
    const lzs_gl=source.lz.filter((item) =>((item[smeta_enum.lz.Glava]==id)&&(item[smeta_enum.lz.smeta]==source.smeta[0][smeta_enum.smeta.Id])))
    for (var i = 0; i < lzs_gl.length; i++) {
      result.push(GetNode("AddZatr",3,[
        GetAttr("Caption",GetValue(lzs_gl,i, smeta_enum.lz.Caption)),
        GetAttr("Level",GetValue(lzs_gl,i, smeta_enum.lz.Level)),
        GetAttr("Options",GetValue(lzs_gl,i, smeta_enum.lz.Options)),
        GetAttr("Value",GetValue(lzs_gl,i, smeta_enum.lz.Value)),
        GetAttr("Formula",GetValue(lzs_gl,i, smeta_enum.lz.Formula)),
      ]))
    }
    return result
  })())}catch{}
    return `<AddZatrGlava Glava="${id}"/>`
}
  const vrgs=[...source.vr].reduce((acc, item, _index) => { 
    if (acc.findIndex(itm => ((itm[smeta_enum.vr.Group_ID] == item[smeta_enum.vr.Group_ID])&&(item[smeta_enum.vr.Group_ID]!==''))||
    ((itm[smeta_enum.vr.Grop_Caption] == item[smeta_enum.vr.Grop_Caption])))>-1) {
          return acc; // если значение уже есть, то просто возвращаем аккумулятор
        }
    //item[smeta_enum.vr.Id]=index+1
  return [...acc, item]; // добавляем к аккумулятору и возвращаем новый аккумулятор
}, []);
    return `<?xml version="1.0" encoding="windows-1251"?>
    <Document ProgramVersion="13.3.1.45649" Generator="GrandSmeta" DocumentType="{2B0470FD-477C-4359-9F34-EEBE36B7D340}">
  ${GetNode('Properties',2,[
    GetAttr('LocNum',GetValue(source.smeta,0,smeta_enum.smeta.LocNum)),
    GetAttr('Object',GetValue(source.smeta,0,smeta_enum.smeta.Object)),
    GetAttr('Constr',GetValue(source.smeta,0,smeta_enum.smeta.Constr)),
    GetAttr('Description',GetValue(source.smeta,0,smeta_enum.smeta.Description))
    ])}
      <GsDocSignatures>
        <Item Caption="Основание" ID="100" ${GetAttr('Value',GetValue(source.smeta,0,smeta_enum.smeta.Reason))}/>
        <Item Caption="Составил" ID="300" ${GetAttr('Value',GetValue(source.smeta,0,smeta_enum.smeta.ComposeFIO))}>
          <Attributes>
            <Item Caption="Должность" ID="970"/>
          </Attributes>
        </Item>
        <Item Caption="Проверил" ID="310" ${GetAttr('Value',GetValue(source.smeta,0,smeta_enum.smeta.VerifyFIO))}>
          <Attributes>
            <Item Caption="Должность" ID="970"/>
          </Attributes>
        </Item>
      </GsDocSignatures>
      ${
        GetNode('DocDates',0,[
          GetAttr('BasePriceDate',GetValue(source.smeta,0,smeta_enum.smeta.BasePriceDate)),
          GetAttr('CurrPriceDate',GetValue(source.smeta,0,smeta_enum.smeta.CurrPriceDate)),
          GetAttr('Options',GetValue(source.smeta,0,smeta_enum.smeta.DocDatesOptions)),
        ])
      }
      <RegionalK Options="Percent Base"/>
      <TerZoneK Caption="Территориальная поправка к базе 2001г" Options="Base PzAll EmAll"/>
      ${''/*${GetNode("Koefficients",0,[],(()=>{
        let result=[]
        const smeta_ks=ks.filter((item) =>(!item[k_col_enum.Position]))
        for (var i = 0; i < smeta_ks.length; i++) {
          result.push(GetNode("K",1,[
            GetAttr('Caption',GetValue(smeta_ks,i,k_col_enum.Caption)),
            GetAttr('Code',GetValue(smeta_ks,i,k_col_enum.Code)),
            GetAttr('Level',GetValue(smeta_ks,i,k_col_enum.Level)),
            GetAttr('Options',GetValue(smeta_ks,i,k_col_enum.Options)),
            GetAttr('Value_EM',GetValue(smeta_ks,i,k_col_enum.Value_EM)),
            GetAttr('Value_MT',GetValue(smeta_ks,i,k_col_enum.Value_MT)),
            GetAttr('Value_OZ',GetValue(smeta_ks,i,k_col_enum.Value_OZ)),
            GetAttr('Value_ZM',GetValue(smeta_ks,i,k_col_enum.Value_ZM)),
          ]))
        }
        return result
      })())}*/}
      ${GetNode('RegionInfo',0,[
        GetAttr('RegionName',GetValue(source.smeta,0,smeta_enum.smeta.RegionName)),
        GetAttr('RegionID',GetValue(source.smeta,0,smeta_enum.smeta.RegionID)),
        GetAttr('Zone84ID',"1"),
        GetAttr('Zone84Name',"Базовый территориальный район"),
        GetAttr('Zone01Name',GetValue(source.smeta,0,smeta_enum.smeta.Zone01Name,'Базовый район')),
        GetAttr('Zone01ID',GetValue(source.smeta,0,smeta_enum.smeta.Zone01ID,'1')),
        GetAttr('AdmRegionCode',GetValue(source.smeta,0,smeta_enum.smeta.AdmRegionCode)),
        GetAttr('AdmRegionZone',GetValue(source.smeta,0,smeta_enum.smeta.AdmRegionZone)),
      ])}
      ${GetNode('FRSN_Info',0,[
        GetAttr("BaseType",GetValue(source.smeta,0,smeta_enum.smeta.BaseType)),
        GetAttr("BaseName",GetValue(source.smeta,0,smeta_enum.smeta.BaseName)),
        GetAttr("RegNumber",GetValue(source.smeta,0,smeta_enum.smeta.RegNumber)),
        GetAttr("RegDate",GetValue(source.smeta,0,smeta_enum.smeta.RegDate)),
        GetAttr("OrderDetails",GetValue(source.smeta,0,smeta_enum.smeta.OrderDetails))
      ],[
        GetNode('Index_Info',2,[
          GetAttr("OrderDetails",GetValue(source.smeta,0,smeta_enum.smeta.Index_Info_OrderDetails)),
          GetAttr("BaseType",GetValue(source.smeta,0,smeta_enum.smeta.Index_Info_BaseType))
        ]
        ),
        GetNode('Wage_Info',2,[
          GetAttr("OrderDetails",GetValue(source.smeta,0,smeta_enum.smeta.Wage_Info_OrderDetails)),
          GetAttr("BaseType",GetValue(source.smeta,0,smeta_enum.smeta.Wage_Info_BaseType))
        ]),
        GetNode('Overhd_Info',2,[
          GetAttr("BaseType",GetValue(source.smeta,0,smeta_enum.smeta.Overhd_BaseType)),
          GetAttr("BaseName",GetValue(source.smeta,0,smeta_enum.smeta.Overhd_BaseName)),
          GetAttr("RegNumber",GetValue(source.smeta,0,smeta_enum.smeta.Overhd_RegNumber)),
          GetAttr("RegDate",GetValue(source.smeta,0,smeta_enum.smeta.Overhd_RegDate)),
          GetAttr("OrderDetails",GetValue(source.smeta,0,smeta_enum.smeta.Overhd_OrderDetails)),
        ]),
        GetNode('Profit_Info',2,[
          GetAttr("BaseType",GetValue(source.smeta,0,smeta_enum.smeta.Profit_BaseType)),
          GetAttr("BaseName",GetValue(source.smeta,0,smeta_enum.smeta.Profit_BaseName)),
          GetAttr("RegNumber",GetValue(source.smeta,0,smeta_enum.smeta.Profit_RegNumber)),
          GetAttr("RegDate",GetValue(source.smeta,0,smeta_enum.smeta.Profit_RegDate)),
          GetAttr("OrderDetails",GetValue(source.smeta,0,smeta_enum.smeta.Profit_OrderDetails)),
        ])
      ]
    )}
    ${GetNode('Parameters',0,[
      GetAttr("Options",GetValue(source.smeta,0,smeta_enum.smeta.Options)),
      GetAttr("Mode2020Order",GetValue(source.smeta,0,smeta_enum.smeta.Mode2020Order)),
      GetAttr("BasePrices",GetValue(source.smeta,0,smeta_enum.smeta.BasePrices)),
      GetAttr("BaseCalcVrs",GetValue(source.smeta,0,smeta_enum.smeta.BaseCalcVrs)),
      GetAttr("TzDigits",GetValue(source.smeta,0,smeta_enum.smeta.TzDigits)),
      GetAttr("BlockRoundMode",GetValue(source.smeta,0,smeta_enum.smeta.BlockRoundMode)),
      GetAttr("MultKPosCalcMode",GetValue(source.smeta,0,smeta_enum.smeta.MultKPosCalcMode)),
      GetAttr("TempZone",GetValue(source.smeta,0,smeta_enum.smeta.TempZone)),
      GetAttr("TsnTempZone",GetValue(source.smeta,0,smeta_enum.smeta.TsnTempZone)),
      GetAttr("MatDigits",GetValue(source.smeta,0,smeta_enum.smeta.MatDigits)),
      GetAttr("MatRoundMode",GetValue(source.smeta,0,smeta_enum.smeta.MatRoundMode)),
      GetAttr("PosKDigits",GetValue(source.smeta,0,smeta_enum.smeta.PosKDigits)),
      GetAttr("ItogOptions",GetValue(source.smeta,0,smeta_enum.smeta.ItogOptions)),
      GetAttr("FirstItogItem",GetValue(source.smeta,0,smeta_enum.smeta.FirstItogItem)),
      GetAttr("ItogExpandTo",GetValue(source.smeta,0,smeta_enum.smeta.ItogExpandTo)),
      GetAttr("PropsConfigName",GetValue(source.smeta,0,smeta_enum.smeta.PropsConfigName)),
      GetAttr("PropsConfigNameModified",GetValue(source.smeta,0,smeta_enum.smeta.PropsConfigNameModified)),
    ]
    ,[
      GetNode('CommonNK',2,[
        GetAttr("CommonK",GetValue(source.smeta,0,smeta_enum.smeta.CommonNK_CommonK)),
        GetAttr("MethodKB",GetValue(source.smeta,0,smeta_enum.smeta.CommonNK_MethodKB)),
        GetAttr("MethodKI",GetValue(source.smeta,0,smeta_enum.smeta.CommonNK_MethodKI)),
        GetAttr("MethodKR",GetValue(source.smeta,0,smeta_enum.smeta.CommonNK_MethodKR)),
        GetAttr("ActiveItems",GetValue(source.smeta,0,smeta_enum.smeta.CommonNK_ActiveItems,"Vk Ck Mk"))
      ]),
      GetNode('CommonPK',2,[
        GetAttr("CommonK",GetValue(source.smeta,0,smeta_enum.smeta.CommonPK_CommonK)),
        GetAttr("MethodKB",GetValue(source.smeta,0,smeta_enum.smeta.CommonPK_MethodKB)),
        GetAttr("MethodKI",GetValue(source.smeta,0,smeta_enum.smeta.CommonPK_MethodKI)),
        GetAttr("MethodKR",GetValue(source.smeta,0,smeta_enum.smeta.CommonPK_MethodKR)),
        GetAttr("ActiveItems",GetValue(source.smeta,0,smeta_enum.smeta.CommonPK_ActiveItems,"Vk Ck Mk"))
      ]),
      GetNode('MtsnNPZpm',2,[]),
      GetNode('Numbering',2,[
        GetAttr("Mode",GetValue(source.smeta,0,smeta_enum.smeta.Numbering_Mode)),
        GetAttr("Options",GetValue(source.smeta,0,smeta_enum.smeta.Numbering_Options)),]),
    ])}
    
     ${GetNode("Indexes",0,[
        GetAttr("IndexesMode",GetValue(source.smeta,0,smeta_enum.smeta.IndexesMode,"None")),
        GetAttr("IndexesLinkMode",GetValue(source.smeta,0,smeta_enum.smeta.IndexesLinkMode,"Default")),
        ],
         [
          GetNode("IndexesPos",1,[],
          (()=>{
            let result=[]
            try{
              const inds_pos=source.index.filter((item) =>(item[smeta_enum.index.Type]=='Pos'))
              for (var i = 0; i < inds_pos.length; i++) {
                result.push(GetNode("Index",2,[
                  GetAttr("Caption",GetValue(inds_pos,i, smeta_enum.index.Caption)),
                  GetAttr("Code",(()=>{
                    //const code=GetValue(inds_pos,i,smeta_enum.index.Code)
                    //if (code) return code
                    return GetValue(inds_pos,i,smeta_enum.index.Id)
                    
                  })()),
                  GetAttr("OZ",GetValue(inds_pos,i, smeta_enum.index.OZ)),
                  GetAttr("EM",GetValue(inds_pos,i, smeta_enum.index.EM)),
                  GetAttr("ZM",GetValue(inds_pos,i, smeta_enum.index.ZM)),
                  GetAttr("MT",GetValue(inds_pos,i, smeta_enum.index.MT)),
                  GetAttr("SMR",GetValue(inds_pos,i, smeta_enum.index.SMR)),
                ]))
              }
            }catch{}
            
            return result
        })()
          ),
          GetNode("IndexesRes",1,[],
          (()=>{
            let result=[]
            try {
              const inds_res=source.index.filter((item) =>(item[smeta_enum.index.Type]=='Res'))
              for (var i = 0; i < inds_res.length; i++) {
                result.push(GetNode("Index",2,[
                  GetAttr("Caption",GetValue(inds_res,i, smeta_enum.index.Caption)),
                  GetAttr("Code",(()=>{
                   // const code=GetValue(inds_res,i,smeta_enum.index.Code)
                    //if (code) return code
                    return GetValue(inds_res,i,smeta_enum.index.Id)
                  })()),
                  GetAttr("OZ",GetValue(inds_res,i, smeta_enum.index.OZ)),
                  GetAttr("EM",GetValue(inds_res,i, smeta_enum.index.EM)),
                  GetAttr("ZM",GetValue(inds_res,i, smeta_enum.index.ZM)),
                  GetAttr("MT",GetValue(inds_res,i, smeta_enum.index.MT)),
                  GetAttr("SMR",GetValue(inds_res,i, smeta_enum.index.SMR)),
                ]))
              }
            }catch{}
            
            return result
          })())
        ]
      )}
       <AddZatrats>
        ${GetGlava("8")}
        ${GetGlava("9")}
        ${GetGlava("10")}
        ${GetGlava("11")}
        ${GetGlava("12")}
        ${GetGlava("13")}
        ${GetGlava("14")}
        ${GetGlava("15")}
      </AddZatrats>
      ${GetNode('OsInfo',0,[
        GetAttr("OSChapter",GetValue(source.smeta,0,smeta_enum.smeta.OSChapter)),
        GetAttr("LinkType",GetValue(source.smeta,0,smeta_enum.smeta.LinkType)),
        GetAttr("Industrial",GetValue(source.smeta,0,smeta_enum.smeta.Industrial)),
      ],[
        GetNode('CCChapter',2,[
          GetAttr("Cons",GetValue(source.smeta,0,smeta_enum.smeta.Cons)),
          GetAttr("Rec",GetValue(source.smeta,0,smeta_enum.smeta.Rec)),
          GetAttr("Road",GetValue(source.smeta,0,smeta_enum.smeta.Road)),
        ])
      ])
      }
    <VidRab_Catalog>
        <Vids_Rab Type="Виды работ 2001г" ${GetAttr('CatFile', GetValue(source.smeta,0,smeta_enum.smeta.CatFile))} ${GetAttr('NrspFile', GetValue(source.smeta,0,smeta_enum.smeta.NrspFile))} ${GetAttr('KfsFiles', GetValue(source.smeta,0,smeta_enum.smeta.KfsFiles))}>${(()=>{
          let result=''
          try{
            for (var i = 0; i < vrgs.length; i++) {
              result=`${result}
              ${GetNode('VidRab_Group',0,[
                GetAttr('Caption', GetValue(vrgs,i,smeta_enum.vr.Grop_Caption)),
                GetAttr('ID', (()=>{
                  const id=GetValue(vrgs,i,smeta_enum.vr.Group_ID)
                  if (id) return id
                  return GetValue(vrgs,i,smeta_enum.vr.Id)
                })())
              ],
              (()=>{
                let result=[]
                try{}catch{}
                const vrs_group=source.vr.filter((item) =>(item[smeta_enum.vr.Grop_Caption]==vrgs[i][smeta_enum.vr.Grop_Caption]))
                for (var k = 0; k < vrs_group.length; k++) {
                result.push(GetNode("Vid_Rab",3,[
                  GetAttr("Caption",GetValue(vrs_group, k, smeta_enum.vr.Caption)),
                  GetAttr('ID', (()=>{
                    const id=GetValue(vrs_group,k,smeta_enum.vr.SourceID)
                    if (id) return id
                    return GetValue(vrs_group,k,smeta_enum.vr.Id)
                  })()),
                  GetAttr("OsColumn",GetValue(vrs_group, k, smeta_enum.vr.OsColumn)),
                  GetAttr("Category",GetValue(vrs_group, k, smeta_enum.vr.Category)),
                  GetAttr("ResGroup",GetValue(vrs_group, k, smeta_enum.vr.ResGroup)),
                  GetAttr("NrCode",GetValue(vrs_group, k, smeta_enum.vr.NrCode)),
                  GetAttr("SpCode",GetValue(vrs_group, k, smeta_enum.vr.SpCode)),
                  GetAttr("Nacl",GetValue(vrs_group, k, smeta_enum.vr.Nacl)),
                  GetAttr("Plan",GetValue(vrs_group, k, smeta_enum.vr.Plan)),
                  GetAttr("NaclCurr",GetValue(vrs_group, k, smeta_enum.vr.NaclCurr)),
                  GetAttr("PlanCurr",GetValue(vrs_group, k, smeta_enum.vr.PlanCurr)),
                  GetAttr("NaclMask",GetValue(vrs_group, k, smeta_enum.vr.NaclMask)),
                  GetAttr("PlanMask",GetValue(vrs_group, k, smeta_enum.vr.PlanMask)),
                  GetAttr("NrKfsCode",GetValue(vrs_group, k, smeta_enum.vr.NrKfsCode)),
                  GetAttr("SpKfsCode",GetValue(vrs_group, k, smeta_enum.vr.SpKfsCode)),
                  GetAttr("NKB",GetValue(vrs_group, k, smeta_enum.vr.NKB)),
                  GetAttr("PNB",GetValue(vrs_group, k, smeta_enum.vr.PNB)),
                  GetAttr("NKI",GetValue(vrs_group, k, smeta_enum.vr.NKI)),
                  GetAttr("PKI",GetValue(vrs_group, k, smeta_enum.vr.PKI)),
                  GetAttr("NKR",GetValue(vrs_group, k, smeta_enum.vr.NKR)),
                  GetAttr("PKR",GetValue(vrs_group, k, smeta_enum.vr.PKR)),
                ]))
              }
              return result})()
              )}`
              }
          }catch{}
            return result
          })() 
        }
        </Vids_Rab>
        <Vids_Rab Type="Виды работ 1984г"/>
      </VidRab_Catalog>
     ${GetNode("Chapters",0,[],
      (()=>{
        let result=[]
        try{
        for (var i = 0; i < source.chapter.length; i++) {
          result.push(GetNode("Chapter",1,[
            GetAttr("Caption",GetValue(source.chapter, i, smeta_enum.chapter.Caption)),
            GetAttr("SysID",String(i+1))
          ],
          (()=>{
            let result=[]
            const char_positions=source.position.filter((item) =>(item[smeta_enum.position.chapter]==GetValue(source.chapter, i, smeta_enum.chapter.Id)))
            for (var k = 0; k < char_positions.length; k++) {
              result.push(GetNode("Position",2,[
                GetAttr("Caption",GetValue(char_positions, k, smeta_enum.position.Caption)),
                GetAttr("Number",GetValue(char_positions, k, smeta_enum.position.Number)),
                GetAttr("Code",GetValue(char_positions, k, smeta_enum.position.Code)),
                GetAttr("Units",GetValue(char_positions, k, smeta_enum.position.Units)),
                GetAttr("SysID",String(k+1)),
                GetAttr("PriceLevel",GetValue(char_positions, k, smeta_enum.position.PriceLevel)),
                GetAttr("DBComment",GetValue(char_positions, k, smeta_enum.position.DBComment)),
                GetAttr("AuxMatCode",GetValue(char_positions, k, smeta_enum.position.AuxMatCode)),
                GetAttr("AuxMat",GetValue(char_positions, k, smeta_enum.position.AuxMat)),
                GetAttr("IndexCode",(()=>{
                  //const ind=source.index.find(item =>(item[smeta_enum.index.Id]==GetValue(char_positions, k, smeta_enum.position.index)))
                  //const ind=source.index[Number(GetValue(char_positions, k, smeta_enum.position.index))-1]
                  //if (ind){
                  //  const code:string=ind[smeta_enum.index.Code]
                  //  if (code) return code
                  //  return ind[smeta_enum.index.Id]
                  //}
                  return GetValue(char_positions, k, smeta_enum.position.index)
                })()),
                GetAttr("Vr2001",(()=>{
                    const vrid=GetValue(char_positions, k, smeta_enum.position.vr)
                  const vr=source.vr.find(item =>(item[smeta_enum.vr.Id]==vrid))
                  //const vr=source.vr[Number(GetValue(char_positions, k, smeta_enum.position.vr))-1]
                  if (vr){
                    const id:string=vr[smeta_enum.vr.SourceID]
                    if (id) return id
                    return vr[smeta_enum.vr.Id]
                  }
                  return ''
                })()),
                GetAttr("NKB",GetValue(char_positions, k, smeta_enum.position.NKB)),
                GetAttr("PNB",GetValue(char_positions, k, smeta_enum.position.PNB)),
                GetAttr("NKI",GetValue(char_positions, k, smeta_enum.position.NKI)),
                GetAttr("PKI",GetValue(char_positions, k, smeta_enum.position.PKI)),
                GetAttr("NKR",GetValue(char_positions, k, smeta_enum.position.NKR)),
                GetAttr("PKR",GetValue(char_positions, k, smeta_enum.position.PKR)),
                GetAttr("Quantity",GetValue(char_positions, k, smeta_enum.position.Quantity)),
                GetAttr("Options",GetValue(char_positions, k, smeta_enum.position.Options)),
                GetAttr("SlaveRow",GetValue(char_positions, k, smeta_enum.position.SlaveRow)),
              ],
              [
                GetNode("Quantity",3,[
                  GetAttr("Fx",`${GetValue(char_positions, k, smeta_enum.position.Quantity)}`),
                  GetAttr("Precision",`${GetValue(char_positions, k, smeta_enum.position.Precision)}`),
                  GetAttr("Result",GetValue(char_positions, k, smeta_enum.position.Quantity)),
                ]),
                GetNode("PriceBase",3,[
                  GetAttr("OZ",GetValue(char_positions, k, smeta_enum.position.BOZ)),
                  GetAttr("EM",GetValue(char_positions, k, smeta_enum.position.BEM)),
                  GetAttr("ZM",GetValue(char_positions, k, smeta_enum.position.BZM)),
                  GetAttr("MT",GetValue(char_positions, k, smeta_enum.position.BMT)),
                  GetAttr("PZ",GetValue(char_positions, k, smeta_enum.position.BPZ)),
                ]),
                GetNode("PriceCurr",3,[
                  GetAttr("OZ",GetValue(char_positions, k, smeta_enum.position.COZ)),
                  GetAttr("EM",GetValue(char_positions, k, smeta_enum.position.CEM)),
                  GetAttr("ZM",GetValue(char_positions, k, smeta_enum.position.CZM)),
                  GetAttr("MT",GetValue(char_positions, k, smeta_enum.position.CMT)),
                ]),
                GetNode("Resources",3,[],
                (()=>{
                  let result=[]
                  try{
                    const ress=source.resource.filter((item) =>(item[smeta_enum.resource.position]==GetValue(char_positions, k, smeta_enum.position.Id)))
                  for (var l = 0; l < ress.length; l++){
                    result.push(GetNode(GetValue(ress,l,smeta_enum.resource.Type),4,
                    [
                      GetAttr("Caption",GetValue(ress, l, smeta_enum.resource.Caption)),
                      GetAttr("Code",GetValue(ress, l, smeta_enum.resource.Code)),
                      GetAttr("Units",GetValue(ress, l, smeta_enum.resource.Units)),
                      GetAttr("Quantity",GetValue(ress, l, smeta_enum.resource.Quantity)),
                      GetAttr("WorkClass",GetValue(ress, l, smeta_enum.resource.WorkClass)),
                      GetAttr("Options",GetValue(ress, l, smeta_enum.resource.Options)),
                      GetAttr("Attribs",GetValue(ress, l, smeta_enum.resource.Attribs)),
                      GetAttr("Mass",GetValue(ress, l, smeta_enum.resource.Mass)),
                      GetAttr("IndexCode",(()=>{
                       // const ind=source.index[Number(GetValue(ress, l, smeta_enum.resource.index))-1]
                        //if (ind){
                        //  const code:string=ind[smeta_enum.index.Code]
                       //   if (code) return code
                       //   return ind[smeta_enum.index.Id]
                       // }
                        return GetValue(ress, l, smeta_enum.resource.index)
                      })()),
                    ],
                    [
                      GetNode("PriceBase",5,[
                        GetAttr("Value",GetValue(ress, l, smeta_enum.resource.PriceBaseValue)),
                        GetAttr("ZM",GetValue(ress, l, smeta_enum.resource.PriceBaseZM)),
                      ]),
                      GetNode("PriceCurr",5,[
                        GetAttr("Value",GetValue(ress, l, smeta_enum.resource.PriceCurrValue)),
                        GetAttr("ZM",GetValue(ress, l, smeta_enum.resource.PriceCurrZM)),
                      ]),
                      GetNode("Tzm2022",5,[
                        GetAttr("WorkClass",GetValue(ress, l, smeta_enum.resource.TzmWorkClass)),
                        GetAttr("Quantity",GetValue(ress, l, smeta_enum.resource.TzmQuantity)),
                        GetAttr("Price",GetValue(ress, l, smeta_enum.resource.TzmPrice)),
                      ]),
                      GetNode("Transportation",5,[],
                      (()=>{
                        let result=[]
                          const items=source.trans.filter((item) =>(item[smeta_enum.trans.resource]==GetValue(ress, l, smeta_enum.resource.Id)))
                          for (var m = 0; m < items.length; m++){
                            result.push(GetNode("Item",6,[
                              GetAttr("Caption",GetValue(items, m, smeta_enum.trans.Caption)),
                              GetAttr("Code",GetValue(items, m, smeta_enum.trans.Code)),
                              GetAttr("Units",GetValue(items, m, smeta_enum.trans.Units)),
                              GetAttr("PriceCurr",GetValue(items, m, smeta_enum.trans.PriceCurr)),
                              GetAttr("KC",GetValue(items, m, smeta_enum.trans.KC)),
                            ]))
                          }
                          return result
                      })())
                    ]
                    )
                    )
                  }
                  }catch{}
                  
                  return result
                })()
                ),
                GetNode("Koefficients",3,[],(()=>{
                  let result=[]
                  const smeta_ks=source.koeff.filter((item) =>(item[smeta_enum.koeff.position]==GetValue(char_positions, k, smeta_enum.position.Id)))
                  for (var i = 0; i < smeta_ks.length; i++) {
                    result.push(GetNode("K",4,[
                      GetAttr('Caption',GetValue(smeta_ks,i,smeta_enum.koeff.Caption)),
                      GetAttr('Code',GetValue(smeta_ks,i,smeta_enum.koeff.Code)),
                      GetAttr('Level',GetValue(smeta_ks,i,smeta_enum.koeff.Level)),
                      GetAttr('Options',GetValue(smeta_ks,i,smeta_enum.koeff.Options)),
                      GetAttr('Value_EM',GetValue(smeta_ks,i,smeta_enum.koeff.Value_EM)),
                      GetAttr('Value_MT',GetValue(smeta_ks,i,smeta_enum.koeff.Value_MT)),
                      GetAttr('Value_OZ',GetValue(smeta_ks,i,smeta_enum.koeff.Value_OZ)),
                      GetAttr('Value_ZM',GetValue(smeta_ks,i,smeta_enum.koeff.Value_ZM)),
                      GetAttr('Value_PZ',GetValue(smeta_ks,i,smeta_enum.koeff.Value_PZ)),
                    ]))
                  }
                  return result
                })()),
                GetNode("WorksList",3,[],
                (()=>{
                  let result=[]
                  try{
                    const poswls=source.wl.filter((item) =>(item[smeta_enum.wl.position]==GetValue(char_positions, k, smeta_enum.position.Id)))
                    for (var l = 0; l < poswls.length; l++){
                      result.push(GetNode("Work",4,[GetAttr("Caption",GetValue(poswls, l, smeta_enum.wl.Caption))]))
                    }
                  }catch{}
                  
                  return result
                })()
                )
              ]
              ))
          }
          return result})()
          ))
        }          
        }catch{}

        return result})()
      )}
      <ReportOptions Options="Grouping SeparateZakPodr" Kk="1" RangingGroups="Mch Mat">
        <RangingRates Mch="20" Mat="20"/>
      </ReportOptions>
    </Document>`
}