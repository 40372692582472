/* eslint-disable eqeqeq */
/* eslint-disable new-parens */
/* eslint-disable no-throw-literal */
import React, { useState } from "react"
import { useQuery } from "@apollo/client";
import { GET_NORMATIVS } from "../gql/query";
import { CreateAsGGE } from "./CreateAsGGE";

export const UploadFile = (props:any) => {
  

    const [ErrMsg, addErrMsg] = useState('');
    const arrayreader = new FileReader();
    const { data:normdata } = useQuery(GET_NORMATIVS);
    const   handleFileChange= async (e:any) => {
        try{
          e.preventDefault(); 

              let file = e.target.files[0];
              //let filename: string = file.name
              //filename = filename.replace(/\.\w+$/, "")
              const SaveDoc = async (text :string) => {
                try {
                  if (!text.includes("{2B0470FD-477C-4359-9F34-EEBE36B7D340}")) throw "Not LS"
                 // addSource(text)
                 // addSourceName(filename)
                  addErrMsg('Загрузить можно только локальные сметы РИМ в формате .gge')
                  //let inputrows=[]
                  //if (props.rows) inputrows=props.rows.inputrows
                  //await CreateAsGrand(inputrows,text, file.name)
                  props.onUpload()
                } catch (err) {
                  addErrMsg('Файл имеет неизвестную структуру. ')
                  
                    switch (err) {                       
                        
                        case 'Not LS':
                          addErrMsg('Загрузить можно только локальные сметы.')
                            break
                    }                      
                    //addSource('')
                   // addSourceName('')      
                          
  
                }
            }
   
                await arrayreader.abort
                let source:any
                await arrayreader.readAsArrayBuffer(file)
                arrayreader.onloadend = async () => {
                    try {
  
                        //this.setState({
                        //    file: file,
                        //    imagePreviewUrl: reader.result
                        //});
                        let ConvertRus = async (text:string, encoding:string, callback:any) => {
                                var bb = new Blob([text]);
                                var f = new FileReader();
                                f.onload = function (e) {

                                    if (e.target)  callback(e.target.result);
                                };
                                f.readAsText(bb, encoding);
                                
                            }
                        if (file.type == "text/xml"){
                          await ConvertRus(file,"windows-1251", function (text:string) {
                            SaveDoc(text)
                            //return text
                        })
                        } else if (file.name.match(/(\.gsfx)$/)) {
                        source = arrayreader.result                      
                        let readFiles = async (url:string) => {
                            var JSZip = require("jszip");
                            var new_zip = new JSZip
                            //let value:string
                            await new_zip.loadAsync(url)
                            const value2 = await new_zip.file("Data.xml").async("uint8array")
                            await ConvertRus(value2,"windows-1251", function (text:string) {
                                SaveDoc(text)
                                //return text
                            })
                            
                        }
                        await readFiles(source)
                      }else if (file.name.match(/(\.gge)$/)) {
                        await ConvertRus(file, "utf-8",
                          (async (text:string)=>{
                            try {
                              if (!text.includes("LocalEstimateResourceIndexMethod")) throw "Not LS"
                              //addSource(text)
                              //addSourceName(filename)
                              addErrMsg('')
                              let norms:any[]=[]
                              //if (props.rows) inputrows=props.rows.inputrows
                              if (normdata) norms=normdata.normativs
                              const tbls =CreateAsGGE([],text, file.name, norms)
                              
                              props.onUpload(tbls,text)
                            } catch (err) {
                              addErrMsg('Файл имеет неизвестную структуру. ')
                              
                                switch (err) {                       
                                    
                                    case 'Not LS':
                                      addErrMsg('Загрузить можно только локальные сметы РИМ (Ресурсно-индексный метод).')
                                        break
                                }              
                                props.onUpload(undefined)        
                                //addSource('')
                                //addSourceName('')      
                                      
              
                            }
                          }))
                          //return text
                        
                            
                      }else {
                        // addNewCaption("Создать")
                         addErrMsg( 'Этот тип файла не поддерживается. Загрузить можно только файлы  смет из программы Грандсмета с расширением .xml или .gsfx')
                        // NoFileTrue()
                        //addSource('')
                        //addSourceName('')
                        props.onUpload(undefined) 
                       }
                        //arrayreader = undefined
  
                    } catch{
                      //addNewCaption("Создать")
                      addErrMsg('Файл имеет неизвестную структуру.')
                      props.onUpload(undefined) 
                      //addSource('')
                      //addSourceName('')
                     
  
                    }
                }
  
  
                // throw new SyntaxError("Данные некорректны");
             
             
  
        }catch (err) {
          //addNewCaption("Создать")
          addErrMsg("Файл не загружен!")
          props.onUpload(undefined) 
          //NoFileTrue()
      }
        
      }
    return (
        <>
        {!(props.hidden) ? (
         <input
            type="file"
            accept=".gge" 
            onChange={  (e) =>  handleFileChange(e)} 
            //id={buttonId}   
            //disabled={(formstate()!=='')}                
        />) : ('Сметный документ загружен.')
        }
        {!(ErrMsg=='') && (
          <div>
            {ErrMsg}
          </div>
        )}

        </>
        )
    }