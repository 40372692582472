import React, { useEffect, useState } from 'react'
import { IInputTable } from '../types/smeta_tables';
import { UploadFile } from '../components/UploadFile';
import { DownloadFile } from '../components/DownloadFile';
import { WithNDSSimple } from '../components/WithNDSSimple';
//import { WithKMatSimple } from '../components/WithKMatSimple';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { TooltipHost } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

const calloutProps = { gapSpace: 0 };
export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export const Convert = () => { 
  const [Source, addSource] = useState(undefined);

  const tooltipId = useId('tooltip');
  //const tooltipId1 = useId('tooltip1');
  const tooltipId2 = useId('tooltip2');
  initializeIcons(/* optional base url */);

  useEffect(() => {
    // Обновляем заголовок документа 
    document.title = 'Смета FORTE Конвертер ГГЕ';
  });

  return (
    <div>
          <br/>
          <TooltipHost
                content="Можно выбрать только файл в формате .gge со сметой составленной ресурсно-индексным методом."
                // This id is used on the tooltip itself, not the host
                // (so an element with this id only exists when the tooltip is shown)
                id={tooltipId}
                calloutProps={calloutProps}
              >
            <UploadFile
              onUpload={(tbls:IInputTable[])=>{                          
              addSource(tbls)
              }}
              aria-describedby={tooltipId}
            />
          </TooltipHost>
          
          {(Source) ? (
            <div >
              {/*<TooltipHost
                content="Позволяет учесть вспомогательные ненормируемые материальные ресурсы в смете согласно приказу Минстроя России от 18.05.2022 №378/пр."
                // This id is used on the tooltip itself, not the host
                // (so an element with this id only exists when the tooltip is shown)
                id={tooltipId1}
                calloutProps={calloutProps}
              >
                <WithKMatSimple
                    source={Source}
                    aria-describedby={tooltipId1}
                  />
              </TooltipHost>*/}
              <TooltipHost
                content={`Выберите необходимый расчёт НДС. 
                В случае выбора "Компенсация по УСН" учтена формула "(МАТ+(ЭМ-ЗПМ)+НР*0,1712+СП*0,15+О)*0,2".
                В случае выбора "Компенсация по УСН2" учтена формула "(МАТ+ЭМ+О)*0,2".`}
                // This id is used on the tooltip itself, not the host
                // (so an element with this id only exists when the tooltip is shown)
                id={tooltipId2}
                calloutProps={calloutProps}
              >
                <WithNDSSimple
                  source={Source}
                  aria-describedby={tooltipId2}
                  />
              </TooltipHost>    
                  
                  <DownloadFile source={Source}/>
            </div>):(
            <div>
                  <DownloadFile source={Source} disabled={!(Source)}/>
            </div>
            )
          }
    </div>
  );
};