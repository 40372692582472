/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */

import { PrimaryButton } from "@fluentui/react";
import { IDownloadFile, smeta_enum } from "../types/smeta_tables";
import unicodeToWin1251 from "./unicodeToWin1251";
import { useMutation } from "@apollo/client";
import { CH_STATE } from "../gql/mutation";
import { useState } from "react";
import { AsGrandXML } from "./AsGrandXML";
export const DownloadFile = props => {
  const [ErrMsg, addErrMsg] = useState('');
  const [ChState] = useMutation(CH_STATE, { 
    variables:{
      text: `convert ${props.source?props.source.filename:undefined}`,

    },
  onCompleted: () => {
  }
      
    });
   const Download= ()=>  {
    let file:IDownloadFile
    try{
    
    ChState()
    const xml =AsGrandXML(props.source)
    
    const win1251Array:any = unicodeToWin1251(xml);
    let blob = new Blob([win1251Array], { type: 'text/xml; charset=windows-1251;' });
    const filename = `${props.source.smeta[0][smeta_enum.smeta.FileName]}.xml`
    //if (window.navigator.msSaveOrOpenBlob) {
        // Internet Explorer
    //    ;
    //    window.navigator.msSaveOrOpenBlob(blob, filename);

   // } else {
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.download = filename;
        link.href = window.URL.createObjectURL(blob);
        link.click();
        document.body.removeChild(link); //remove the link when done
  // }
    }catch(err){
      console.log(err)
      addErrMsg('В процессе конвертации файла произошла ошибка. Свяжитесь с разработчиком')
}

    return;
}
    return (
        <>
        <div>
          <br /> 
            <PrimaryButton 
              text="Скачать в формате Грандсмета(XML)"
              onClick={async()=>{
                Download()}} 
                disabled={props.disabled}
                />
              {!(ErrMsg=='') && (
          <div>
            {ErrMsg}
          </div>
        )}
        </div>
        </>
        
      );
}


