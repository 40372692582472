/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-undef */
/* eslint-disable eqeqeq */

import {IInputSmeta, region_enum, smeta_enum} from "../types/smeta_tables";
import {  GetRow, GetXMLNodeContent, GetXMLNodes, RowIndex } from "./UtilsTbls";

export const CreateAsGGE =  (inputrows, source:string, filename:string, normativs:any[]) => {
    let gge_smeta:IInputSmeta
    try{
        
        source=source.replace('×','x')
        source=source.replace('⌀','')
        source=source.replace('Ø','')
        source=source.replace('⁰','')
        source=source.replace('²','2')
        source=source.replace('ø','2')
        source=source.replace('℃','')
        source=source.replace('½','1/2')
        source=source.replace('¾','3/4')
        const CreateSmeta=( xml:any)=>{
            gge_smeta={
                smeta:[],
                vr:[],
                index:[],
                chapter:[],
                position:[],
                koeff:[],
                lz:[],
                resource:[],
                trans:[],
                wl:[]
            }
            let smeta_row =GetRow(smeta_enum,"smeta",inputrows)
            smeta_row[smeta_enum.smeta.FileName]=filename.replace(/(\.gge)$/,'')
            smeta_row[smeta_enum.smeta.Id]=RowIndex(gge_smeta,"smeta")    
              
            const normativ=normativs.filter(item=>((item.NormId==GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Num"))&&(item.Mask=='')))
            normativ.forEach(item=>{
                smeta_row[smeta_enum.smeta.RegionID]=item.RegionID
                smeta_row[smeta_enum.smeta.RegionName]=item.RegionName
                smeta_row[smeta_enum.smeta.NrspFile]=item.NrspFile
                smeta_row[smeta_enum.smeta.CatFile]=item.CatFile
                smeta_row[smeta_enum.smeta.Overhd_OrderDetails]=item.Overhd_OrderDetails
                smeta_row[smeta_enum.smeta.Profit_OrderDetails]=item.Profit_OrderDetails
            })
            smeta_row[smeta_enum.smeta.Generator]=GetXMLNodeContent(xml,"Meta/Soft/Name")
            smeta_row[smeta_enum.smeta.ProgramVersion]=GetXMLNodeContent(xml,"Meta/Soft/Version")
            smeta_row[smeta_enum.smeta.LocNum]=`'${GetXMLNodeContent(xml,`Object/Num`)}`
            smeta_row[smeta_enum.smeta.Object]=GetXMLNodeContent(xml,`Object/Name`)
            smeta_row[smeta_enum.smeta.Constr]=GetXMLNodeContent(xml,`Name`)
            smeta_row[smeta_enum.smeta.Reason]=GetXMLNodeContent(xml,`Object/Estimate/Reason`)
            smeta_row[smeta_enum.smeta.LinkType]='RS'
            smeta_row[smeta_enum.smeta.BasePriceDate]= 
                (GetXMLNodeContent(xml,"Object/Estimate/Date/Day")=='')?'':
                `${((value:string)=>(`${'0'.repeat(2-value.length)}${value}`))(GetXMLNodeContent(xml,"Object/Estimate/Date/Day"))
                }.${((value:string)=>(`${'0'.repeat(2-value.length)}${value}`))(GetXMLNodeContent(xml,"Object/Estimate/Date/Month"))
                }.${GetXMLNodeContent(xml,"Object/Estimate/Date/Year")}`
            smeta_row[smeta_enum.smeta.BasePriceDate]= 
                (GetXMLNodeContent(xml,"Object/Estimate/PriceLevelBase/Year")=='')?'':
                `${((value:string)=>(`${'0'.repeat(2-value.length)}${value}`))((GetXMLNodeContent(xml,"Object/Estimate/PriceLevelBase/Day")=='')?'1':GetXMLNodeContent(xml,"Object/Estimate/PriceLevelBase/Day"))
                }.${((value:string)=>(`${'0'.repeat(2-value.length)}${value}`))(GetXMLNodeContent(xml,"Object/Estimate/PriceLevelBase/Month"))
                }.${GetXMLNodeContent(xml,"Object/Estimate/PriceLevelBase/Year")}`
            smeta_row[smeta_enum.smeta.CurrPriceDate]=(()=>{
                const Quarter=Number(GetXMLNodeContent(xml,`Object/Estimate/PriceLevelCur/Quarter`))
                if (Quarter==1) return `01.01.${GetXMLNodeContent(xml,`Object/Estimate/PriceLevelCur/Year`)}`
                if (Quarter==2) return `01.04.${GetXMLNodeContent(xml,`Object/Estimate/PriceLevelCur/Year`)}`    
                if (Quarter==3) return `01.07.${GetXMLNodeContent(xml,`Object/Estimate/PriceLevelCur/Year`)}`
                if (Quarter==4) return `01.10.${GetXMLNodeContent(xml,`Object/Estimate/PriceLevelCur/Year`)}`
                return ''
            })()  
            smeta_row[smeta_enum.smeta.Options]="AddZatr ZpmByTz 2020Mode SeparateFot MultPosK 2022Mode 2020NoRound"
            smeta_row[smeta_enum.smeta.BasePrices]="2001"
            smeta_row[smeta_enum.smeta.BaseCalcVrs]="Vr2001"
            smeta_row[smeta_enum.smeta.TzDigits]="2"
            smeta_row[smeta_enum.smeta.BlockRoundMode]="Smart"
            smeta_row[smeta_enum.smeta.MultKPosCalcMode]="UseTotalMult"
            smeta_row[smeta_enum.smeta.TempZone]="1"
            smeta_row[smeta_enum.smeta.TsnTempZone]="1"
            smeta_row[smeta_enum.smeta.MatDigits]="4"
            smeta_row[smeta_enum.smeta.MatRoundMode]="None"
            smeta_row[smeta_enum.smeta.PosKDigits]="7"
            smeta_row[smeta_enum.smeta.ItogOptions]="SeparateVrs IgnoreZeroQty IgnoreZeroPrice"
            smeta_row[smeta_enum.smeta.FirstItogItem]="K"
            smeta_row[smeta_enum.smeta.ItogExpandTo]="Default"
            smeta_row[smeta_enum.smeta.PropsConfigName]="НС. Ресурсно-индексный метод расчета(Методики 421(в ред.557), 812(в ред.636 и 611), 774(в ред.317))"
            smeta_row[smeta_enum.smeta.Numbering_Mode]="Sequential"
            smeta_row[smeta_enum.smeta.Numbering_Options]="Auto SlaveSubNum"
            smeta_row[smeta_enum.smeta.IndexesLinkMode]="Category"
            smeta_row[smeta_enum.smeta.OSChapter]="1"
            smeta_row[smeta_enum.smeta.Cons]="2"
            smeta_row[smeta_enum.smeta.Rec]="2"
            smeta_row[smeta_enum.smeta.Road]="2"
            smeta_row[smeta_enum.smeta.DocDatesOptions]="CurrFmtQr"
            smeta_row[smeta_enum.smeta.Index_Info_BaseType]="INFO"
            smeta_row[smeta_enum.smeta.Wage_Info_BaseType]="INFO"
            smeta_row[smeta_enum.smeta.Zone01Name]="Базовый район"
            smeta_row[smeta_enum.smeta.Zone01ID]="1"
            smeta_row[smeta_enum.smeta.Description]=GetXMLNodeContent(xml,"Object/Estimate/Name")
            let AdmRegionCode=GetXMLNodeContent(xml,"Object/Estimate/Region/RegionCode")
            AdmRegionCode=(AdmRegionCode=='')?GetXMLNodeContent(xml,"Object/Region/Code"):AdmRegionCode
            if (AdmRegionCode){
                let regionname=GetXMLNodeContent(xml,"Object/Estimate/Region/RegionName")
                regionname=(regionname=='')?GetXMLNodeContent(xml,"Object/Region/Name"):regionname
                smeta_row[smeta_enum.smeta.AdmRegionName]=(regionname)?regionname:region_enum.find(item=> (String(item.Code)== AdmRegionCode)).Name
                smeta_row[smeta_enum.smeta.AdmRegionZone]=(regionname)?regionname:region_enum.find(item=> (String(item.Code)== AdmRegionCode)).Name
                smeta_row[smeta_enum.smeta.AdmRegionCode]=AdmRegionCode
            }

            //smeta_row[smeta_enum.smeta.AdmRegionZone]=GetXMLNodeContent(xml,"Object/Estimate/Region/SubRegion/SubRegionCode")
            smeta_row[smeta_enum.smeta.Index_Info_OrderDetails]=GetXMLNodeContent(xml,"Object/Estimate/Legal/Indexes/Orders")
            smeta_row[smeta_enum.smeta.Wage_Info_OrderDetails]=GetXMLNodeContent(xml,"Object/Estimate/Salary")
            smeta_row[smeta_enum.smeta.ComposeFIO]=GetXMLNodeContent(xml,"Object/Estimate/Signatures/ComposeFIO")
            smeta_row[smeta_enum.smeta.VerifyFIO]=GetXMLNodeContent(xml,"Object/Estimate/Signatures/VerifyFIO")
            
            smeta_row[smeta_enum.smeta.Overhd_BaseName]=GetXMLNodeContent(xml,"Object/Estimate/Legal/Overheads/Name")
            smeta_row[smeta_enum.smeta.Overhd_OrderDetails]=GetXMLNodeContent(xml,"Object/Estimate/Legal/Overheads/Orders")
            smeta_row[smeta_enum.smeta.Overhd_RegNumber]=GetXMLNodeContent(xml,"Object/Estimate/Legal/Overheads/Num")
            smeta_row[smeta_enum.smeta.Overhd_RegDate]=(GetXMLNodeContent(xml,"Object/Estimate/Legal/Overheads/Date/Day")=='')?'':
            `${((value:string)=>(`${'0'.repeat(2-value.length)}${value}`))(GetXMLNodeContent(xml,"Object/Estimate/Legal/Overheads/Date/Day"))
            }.${((value:string)=>(`${'0'.repeat(2-value.length)}${value}`))(GetXMLNodeContent(xml,"Object/Estimate/Legal/Overheads/Date/Month"))
            }.${GetXMLNodeContent(xml,"Object/Estimate/Legal/Overheads/Date/Year")}`
            smeta_row[smeta_enum.smeta.Overhd_BaseType]= (()=>{
                let Value=GetXMLNodeContent(xml,"Object/Estimate/Legal/Overheads/Type")
                    if (Value=="Справочная информация") return "INFO"
                    Value=Value.replace('Г','G')
                    .replace('Э','E')
                    .replace('С','S')
                    .replace('Н','N')
                    .replace('Ф','F')
                    .replace('Т','T')
                    .replace('И','I')
                return Value
                })()
                smeta_row[smeta_enum.smeta.Profit_BaseName]= GetXMLNodeContent(xml,"Object/Estimate/Legal/Profits/Name")
                smeta_row[smeta_enum.smeta.Profit_RegNumber]= GetXMLNodeContent(xml,"Object/Estimate/Legal/Profits/Num")
                smeta_row[smeta_enum.smeta.Profit_OrderDetails]= GetXMLNodeContent(xml,"Object/Estimate/Legal/Profits/Orders")
                smeta_row[smeta_enum.smeta.Profit_RegDate]= 
                (GetXMLNodeContent(xml,"Object/Estimate/Legal/Profits/Date/Day")=='')?'':
                `${((value:string)=>(`${'0'.repeat(2-value.length)}${value}`))(GetXMLNodeContent(xml,"Object/Estimate/Legal/Profits/Date/Day"))
                }.${((value:string)=>(`${'0'.repeat(2-value.length)}${value}`))(GetXMLNodeContent(xml,"Object/Estimate/Legal/Profits/Date/Month"))
                }.${GetXMLNodeContent(xml,"Object/Estimate/Legal/Profits/Date/Year")}`
                
                smeta_row[smeta_enum.smeta.Profit_BaseType]= (()=>{
                let Value=GetXMLNodeContent(xml,"Object/Estimate/Legal/Profits/Type")
                    if (Value=="Справочная информация") return "INFO"
                    Value=Value.replace('Г','G')
                    .replace('Э','E')
                    .replace('С','S')
                    .replace('Н','N')
                    .replace('Ф','F')
                    .replace('Т','T')
                    .replace('И','I')
                return Value
                })()
                smeta_row[smeta_enum.smeta.BaseType]= (()=>{
                let Value=GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Type")
                if (Value=="Справочная информация") return "INFO"
                Value=Value.replace('Г','G')
                .replace('Э','E')
                .replace('С','S')
                .replace('Н','N')
                .replace('Ф','F')
                .replace('Т','T')
                .replace('И','I')
                return Value
            })()
            smeta_row[smeta_enum.smeta.BaseName]= GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Name")
            smeta_row[smeta_enum.smeta.OrderDetails]= GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Orders")
            smeta_row[smeta_enum.smeta.RegNumber]= GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Num")
            smeta_row[smeta_enum.smeta.RegDate]= 
                (GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Date/Day")=='')?'':
                `${((value:string)=>(`${'0'.repeat(2-value.length)}${value}`))(GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Date/Day"))
                }.${((value:string)=>(`${'0'.repeat(2-value.length)}${value}`))(GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Date/Month"))
                }.${GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Date/Year")}`
                
            const Chapters=GetXMLNodes(xml,"Object/Estimate/Sections/Section")
            Chapters.forEach((item:any)=>{ 
                let chapter_row =GetRow(smeta_enum,"chapter",inputrows)
                chapter_row[smeta_enum.chapter.Caption]=GetXMLNodeContent(item,"Name")
                chapter_row[smeta_enum.chapter.Id]=RowIndex(gge_smeta,"chapter") 
                chapter_row[smeta_enum.chapter.smeta]=RowIndex(gge_smeta,"smeta")                
                const Positions=GetXMLNodes(item,"Items/Item")
                Positions.forEach((itm:any)=>{ 
                   let position_row =GetRow(smeta_enum,"position",inputrows)
                   position_row[smeta_enum.position.chapter]=RowIndex(gge_smeta,"chapter")
                   position_row[smeta_enum.position.Id]=RowIndex(gge_smeta,"position")
                   const code:string =`${GetXMLNodeContent(itm,'Cost/Prefix')}${
                    GetXMLNodeContent(itm,'Cost/Code',
                    GetXMLNodeContent(itm,'Transport/Code',
                    GetXMLNodeContent(itm,'Equipment/Code',
                    GetXMLNodeContent(itm,'Material/Code'))))}`
                    if (GetXMLNodeContent(itm,'Cost/Prefix')!==''){
                        const dbcomments= normativs.filter(item=>((item.NormId==GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Num"))&&(code.startsWith(item.Mask)))).sort(item.Mask)
                        dbcomments.forEach(nrm=>{
                        position_row[smeta_enum.position.DBComment]=nrm.DBComment
                        })
                    }
                   
                    position_row[smeta_enum.position.Number]=
                        GetXMLNodeContent(itm,'Cost/Num',
                        GetXMLNodeContent(itm,'Transport/Num',
                        GetXMLNodeContent(itm,'Equipment/Num',
                        GetXMLNodeContent(itm,'Material/Num'))))
                    position_row[smeta_enum.position.Code]=code
                    position_row[smeta_enum.position.Caption]=
                        GetXMLNodeContent(itm,'Cost/Name',
                        GetXMLNodeContent(itm,'Transport/Name',
                        GetXMLNodeContent(itm,'Equipment/Name', 
                        GetXMLNodeContent(itm,'Material/Name'))))
                    position_row[smeta_enum.position.Units]=
                        GetXMLNodeContent(itm,'Cost/Unit',
                        GetXMLNodeContent(itm,'Transport/Unit',
                        GetXMLNodeContent(itm,'Equipment/Unit', 
                        GetXMLNodeContent(itm,'Material/Unit'))))
                    position_row[smeta_enum.position.vr]=
                            (()=>{
                                let curvr=GetRow(smeta_enum,"vr",inputrows)
                                curvr[smeta_enum.vr.smeta]=RowIndex(gge_smeta,"smeta") 
                                //SetValue(curvr,smeta_enum.vrs.Row,String(tblvrs.rows.length+1))
                                curvr[smeta_enum.vr.Id]=RowIndex(gge_smeta,"vr")
                                curvr[smeta_enum.vr.Grop_Caption]= GetXMLNodeContent(itm,'WorkType')
                                curvr[smeta_enum.vr.NrCode]= GetXMLNodeContent(itm,'Overheads/Reason')
                                curvr[smeta_enum.vr.NaclMask]= GetXMLNodeContent(itm,'Overheads/Target',"ФОТ")
                                curvr[smeta_enum.vr.Caption]= GetXMLNodeContent(itm,'Overheads/Name',
                                (()=>{
                                    if (GetXMLNodeContent(itm,"Transport")) return  'Перевозка грузов'
                                    if (GetXMLNodeContent(itm,"Equipment")) return  'Оборудование'
                                    if (GetXMLNodeContent(itm,"Material")) return  'Материал по прайсу'
                                    return ''
                                })())
                                curvr[smeta_enum.vr.Nacl]= GetXMLNodeContent(itm,'Overheads/Value')
                                curvr[smeta_enum.vr.NaclCurr]= GetXMLNodeContent(itm,'Overheads/Value')
                               // curvr[smeta_enum.vr.NKB]= GetXMLNodeContent(itm,'Overheads/Coefficients/Coefficient/Value')
                                //curvr[smeta_enum.vr.NKI]= GetXMLNodeContent(itm,'Overheads/Coefficients/Coefficient/Value')
                                //curvr[smeta_enum.vr.NKR]= GetXMLNodeContent(itm,'Overheads/Coefficients/Coefficient/Value')
                               // curvr[smeta_enum.vr.NrKfsCode]= GetXMLNodeContent(itm,'Overheads/Coefficients/Coefficient/Reason')
                                curvr[smeta_enum.vr.SpCode]= GetXMLNodeContent(itm,'Profits/Reason')
                                curvr[smeta_enum.vr.PlanMask]= GetXMLNodeContent(itm,'Profits/Target',"ФОТ")
                                curvr[smeta_enum.vr.Plan]= GetXMLNodeContent(itm,'Profits/Value')
                                curvr[smeta_enum.vr.PlanCurr]= GetXMLNodeContent(itm,'Profits/Value')
                                //curvr[smeta_enum.vr.PNB]= GetXMLNodeContent(itm,'Profits/Coefficients/Coefficient/Value')
                                //curvr[smeta_enum.vr.PKI]= GetXMLNodeContent(itm,'Profits/Coefficients/Coefficient/Value')
                                //curvr[smeta_enum.vr.PKR]= GetXMLNodeContent(itm,'Profits/Coefficients/Coefficient/Value')
                               // curvr[smeta_enum.vr.SpKfsCode]= GetXMLNodeContent(itm,'Profits/Coefficients/Coefficient/Reason')
                                curvr[smeta_enum.vr.Category]= GetXMLNodeContent(itm,'Overheads/Name',
                                (()=>{
                                    if (GetXMLNodeContent(itm,"Transport")) return  'Transportation'
                                    if (GetXMLNodeContent(itm,"Equipment")) return  'Equipment'
                                    if (GetXMLNodeContent(itm,'WorkType').startsWith('Монтажные')) return  'Mounting'
                                    return 'Construction'
                                })())
                                curvr[smeta_enum.vr.OsColumn]=
                                   (()=>{
                                    const Equipment= GetXMLNodeContent(itm,'Equipment')
                                    if (Equipment) return 'О'
                                    if (GetXMLNodeContent(itm,'WorkType').startsWith('Монтажные')) return 'М'
                                    return ''
                                   })() 
                                let rowindex=gge_smeta.vr.findIndex(item=>{
                                    let result=true
                                    for (var i = 0; i < item.length; i++) {
                                        if ((item[i]!==curvr[i])&&(i!==smeta_enum.vr.Id)) {
                                            result=false
                                            break
                                        }
                                    }
                                    return result
                                })
                                if (rowindex<0) {
                                    rowindex=gge_smeta.vr.length+1
                                    gge_smeta.vr.push(curvr)                                    
                                }else rowindex++
                                return String(rowindex)
                            })()

                    position_row[smeta_enum.position.PriceLevel]=
                        (()=>{
                            if (GetXMLNodeContent(itm,'Material')) {
                                if (GetXMLNodes(itm,'Material/PricePerUnitBase').length==0) return "Curr"
                            }
                            return "2001"
                        })()
                    position_row[smeta_enum.position.Quantity]=
                        GetXMLNodeContent(itm,'Cost/QuantityTotal',
                        GetXMLNodeContent(itm,'Transport/ConsumptionTotal',
                        GetXMLNodeContent(itm,'Equipment/ConsumptionTotal', 
                        GetXMLNodeContent(itm,'Material/ConsumptionTotal')))
                        )
                    position_row[smeta_enum.position.Options]=
                        (()=>{
                            let result=''
                            if (GetXMLNodes(itm,'Material').length>0) {
                                if (GetXMLNodes(itm,'Material/Index').length>0) result=`ForcedResIdx`
                                if (GetXMLNodes(itm,'Material/PricePerUnitBase').length==0) result=`NotInNB`
                            }
                            if (GetXMLNodes(itm,'Equipment/Index').length>0) result=`${result} ForcedResIdx`
                            return result.trim()
                        })()
                    position_row[smeta_enum.position.BMT]=GetXMLNodeContent(itm,'Equipment/PricePerUnitBase',GetXMLNodeContent(itm,'Material/PricePerUnitBase'))
                    position_row[smeta_enum.position.CMT]=Number(GetXMLNodeContent(itm,'Equipment/PricePerUnitCur',
                    GetXMLNodeContent(itm,'Material/PricePerUnitCur',
                    (GetXMLNodeContent(itm,'WorkType')!=='Перевозка')?GetXMLNodeContent(itm,'Transport/PricePerUnitCur'):''))).toFixed(2)
                    position_row[smeta_enum.position.Precision]= (GetXMLNodeContent(itm,'Transport/PricePerUnitCur'))?7:''
                   position_row[smeta_enum.position.CMT]=Number(GetXMLNodeContent(itm,'Equipment/PricePerUnitCur',
                    GetXMLNodeContent(itm,'Material/PricePerUnitCur',
                    (GetXMLNodeContent(itm,'WorkType')!=='Перевозка')?(Number(GetXMLNodeContent(itm,'Transport/PriceTotalCur','0'))/Number(GetXMLNodeContent(itm,'Transport/Consumption','1'))/Number(GetXMLNodeContent(itm,'Transport/Coefficients/Final/Values/Value/CoefValue','1'))).toFixed(2):''))).toFixed(2)
                    position_row[smeta_enum.position.CEM]=(()=>
                        (GetXMLNodeContent(itm,'WorkType')=='Перевозка')?(Number(GetXMLNodeContent(itm,'Transport/PriceTotalCur','0'))/Number(GetXMLNodeContent(itm,'Transport/Consumption','1'))/Number(GetXMLNodeContent(itm,'Transport/Coefficients/Final/Values/Value/CoefValue','1'))).toFixed(2):''
                )()
                    position_row[smeta_enum.position.SlaveRow]=(()=>{
                         if (GetXMLNodes(itm,'Material').length>0) return 'Yes'
                        return ''
                    })()

                     position_row[smeta_enum.position.AuxMat]=(()=>{
                        const Amendments= GetXMLNodeContent(itm,'Amendments')
                        if (Amendments) return String(Math.round(Number(Amendments)*100/Number(GetXMLNodeContent(itm,'Cost/ResourcesInternal/Worker/PriceTotalCur'))))
                       return ''
                   })()
                   position_row[smeta_enum.position.index]=
                    (()=>{
                        let Index =GetXMLNodes(itm,'Equipment/Index')[0]
                        if (!Index) Index =GetXMLNodes(itm,'Material/Index')[0] 
                        let ind=GetRow(smeta_enum,"index",inputrows)
                        ind[smeta_enum.index.smeta]=RowIndex(gge_smeta,"smeta") 
                        if (Index){  
                            ind[smeta_enum.index.Id]=RowIndex(gge_smeta,"index")
                            ind[smeta_enum.index.Type]= 'Pos'
                            ind[smeta_enum.index.Caption]= GetXMLNodeContent(Index,'Name')
                            ind[smeta_enum.index.Code]= GetXMLNodeContent(Index,'Reason')
                            ind[smeta_enum.index.MT]= GetXMLNodeContent(Index,'Value/IndexValue')                      
                            let rowindex=gge_smeta.index.findIndex(item=>{
                            let result=true
                            for (var i = 0; i < item.length; i++) {
                                if ((item[i]!==ind[i])&&(i!==smeta_enum.index.Id)) {
                                    result=false
                                    break
                                }
                            }
                            return result
                            })
                            if (rowindex<0) {
                                rowindex=gge_smeta.index.length+1
                                gge_smeta.index.push(ind)                                    
                            } else rowindex++
                            return String(rowindex)
                        }
                        return ''
                    })()
                  const OverheadsCoefficient=GetXMLNodes(itm,'Overheads/Coefficients/Coefficient')
                    let ovk=''
                    OverheadsCoefficient.forEach((citm:any)=>{
                        ovk=`${ovk}${ (ovk.length>0)?'*':'' }${GetXMLNodeContent(citm,'Value')}`
                    })
                    position_row[smeta_enum.position.NKB]=ovk
                    position_row[smeta_enum.position.NKI]=ovk
                    position_row[smeta_enum.position.NKR]=ovk
                    const ProfitsCoefficient=GetXMLNodes(itm,'Profits/Coefficients/Coefficient')
                    let pk=''
                    ProfitsCoefficient.forEach((citm:any)=>{
                        pk=`${pk}${ (pk.length>0)?'*':'' }${GetXMLNodeContent(citm,'Value')}`
                    })
                    position_row[smeta_enum.position.PNB]=pk
                    position_row[smeta_enum.position.PKI]=pk
                    position_row[smeta_enum.position.PKR]=pk
                    
                    const GetFinalKoeff =Koeff=>{
                        let result={}
                            const Values=GetXMLNodes(Koeff[0],'Values/Value')
                            if (!Values) return result
                            Values.forEach((value:any)=>{
                                switch(GetXMLNodeContent(value,"Target")) {
                                    case 'ЭМ': 
                                        result['EM']=Number(GetXMLNodeContent(value,"CoefValue")) 
                                        break
                                    case 'ЗТ': 
                                        result['TZ']=Number(GetXMLNodeContent(value,"CoefValue"))
                                        break
                                    case 'ЗТМ':  
                                        result['TZM']=Number(GetXMLNodeContent(value,"CoefValue"))
                                        break
                                    case 'ОТ':
                                        result['OZ']=Number(GetXMLNodeContent(value,"CoefValue"))
                                        break
                                    case 'ОТМ':  
                                        result['ZPM']=Number(GetXMLNodeContent(value,"CoefValue"))
                                        break
                                    case 'МАТ': 
                                        result['MAT']=Number(GetXMLNodeContent(value,"CoefValue"))
                                        break
                                    case 'ОБ':  
                                        result['MAT']=Number(GetXMLNodeContent(value,"CoefValue"))
                                        break
                                    case 'Стоимость':  
                                        result['OZ']=Number(GetXMLNodeContent(value,"CoefValue"))
                                        result['EM']=Number(GetXMLNodeContent(value,"CoefValue"))
                                        result['ZPM']=Number(GetXMLNodeContent(value,"CoefValue"))
                                        result['MAT']=Number(GetXMLNodeContent(value,"CoefValue"))
                                        break
                                  }
                            })
                            return result
                    }
                    const GetKoeffs =Koeffs=>{
                        let result=[]
                        Koeffs.forEach((item:number, ind:number)=>{
                            result=[...result,{Name:GetXMLNodeContent(item,"Name"),Reason:GetXMLNodeContent(item,"Reason")}]
                            let Values=GetXMLNodes(item,'Values/Value')
                            Values.forEach((value:any)=>{
                                switch(GetXMLNodeContent(value,"Target")) {
                                    case 'ЭМ':  
                                        result[ind]['EM']=GetXMLNodeContent(value,"CoefValue")
                                        //result[ind]['ZPM']=GetXMLNodeContent(value,"CoefValue")
                                        break
                                    case 'ЗТ': 
                                        //result[ind]['OZ']=GetXMLNodeContent(value,"CoefValue")
                                        result[ind]['TZ']=GetXMLNodeContent(value,"CoefValue")
                                        break
                                    case 'ЗТМ':  
                                        result[ind]['TZM']=GetXMLNodeContent(value,"CoefValue")
                                        break
                                    case 'ОТ':
                                        result[ind]['OZ']=GetXMLNodeContent(value,"CoefValue")
                                        break
                                    case 'ОТМ':  
                                        result[ind]['ZPM']=GetXMLNodeContent(value,"CoefValue")
                                        break
                                    case 'МАТ': 
                                        result[ind]['MAT']=GetXMLNodeContent(value,"CoefValue")
                                        break
                                    case 'ОБ':  
                                        result[ind]['MAT']=GetXMLNodeContent(value,"CoefValue")
                                        break
                                    case 'Стоимость':  
                                        result[ind]['OZ']=GetXMLNodeContent(value,"CoefValue")
                                        result[ind]['EM']=GetXMLNodeContent(value,"CoefValue")
                                        result[ind]['ZPM']=GetXMLNodeContent(value,"CoefValue")
                                        result[ind]['MAT']=GetXMLNodeContent(value,"CoefValue")
                                        break
                                  }
                            })
                        })
                        
                          return result

                    }
                    const GetAddOn10=(FinalK, Ks)=>{
                        let result:string[]
                        if (Ks.length==0) return []
                        const defresult:string[]=`${'0'.repeat(Ks.length)}`.split('')

                        for (var i = 0; i < Math.pow(2,Ks.length); i++) {
                            const TZ=[1,1,1]
                            const OZ=[1,1,1]
                            const EM=[1,1,1]
                            const ZPM=[1,1,1]
                            const TZM=[1,1,1]
                            const MT=[1,1,1]
                            const mask=i.toString(2)
                            result=`${'0'.repeat(Ks.length-mask.length)}${mask}`.split('')
                            for (var k = 0; k < Ks.length; k++) {
                                //const curval=(k>result.length)?0:result[k]
                                const curval=result[k]
                                if (curval=='1'){
                                    if (Ks[k].TZ) {
                                        TZ[0]=TZ[0]+Number(Ks[k].TZ)-1
                                        OZ[0]=OZ[0]+Number(Ks[k].TZ)-1
                                    }else if (Ks[k].OZ) {
                                        OZ[1]=Number((OZ[1]*Ks[k].OZ).toFixed(7))
                                    }
                                    if (Ks[k].EM) {
                                        EM[0]=EM[0]+Number(Ks[k].EM)-1
                                    }
                                    if ((Ks[k].ZPM)&&!(Ks[k].TZM)) {
                                        ZPM[1]=Number((ZPM[1]*Ks[k].ZPM).toFixed(7))
                                    }
                                    if (Ks[k].TZM) {
                                        TZM[0]=TZM[0]+Number(Ks[k].TZM)-1
                                        ZPM[0]=ZPM[0]+Number(Ks[k].TZM)-1
                                        }
                                }else{
                                    if (Ks[k].TZ) {
                                        TZ[2]=Number((TZ[2]*Ks[k].TZ).toFixed(7))
                                        OZ[2]=Number((OZ[2]*Ks[k].TZ).toFixed(7))
                                    }else if (Ks[k].OZ) {
                                        OZ[2]=Number((OZ[2]*Ks[k].OZ).toFixed(7))
                                    }
                                    if (Ks[k].EM) {
                                        EM[2]=Number((EM[2]*Ks[k].EM).toFixed(7))
                                    }
                                    if (Ks[k].ZPM) {
                                        ZPM[2]=Number((ZPM[2]*Ks[k].ZPM).toFixed(7))
                                    }
                                    if (Ks[k].TZM) {
                                        TZM[2]=Number((TZM[2]*Ks[k].TZM).toFixed(7))
                                        ZPM[2]=Number((ZPM[2]*Ks[k].TZM).toFixed(7))
                                    }
                                } 
                            }
                            let ResultK={
                                TZ:Number((Number((TZ[0]*TZ[1]).toFixed(2))*TZ[2]).toFixed(7)),
                                OZ:Number((Number((OZ[0]*OZ[1]).toFixed(2))*OZ[2]).toFixed(7)),
                                EM:Number((Number((EM[0]*EM[1]).toFixed(2))*EM[2]).toFixed(7)),
                                ZPM:Number((Number((ZPM[0]*ZPM[1]).toFixed(2))*ZPM[2]).toFixed(7)),
                                TZM:Number((Number((TZM[0]*TZM[1]).toFixed(2))*TZM[2]).toFixed(7)),
                                MT:Number((Number((MT[0]*MT[1]).toFixed(2))*MT[2]).toFixed(7)),
                            }
                            if (
                                ((FinalK.TZ==undefined)||(ResultK.TZ==FinalK.TZ))
                                &&((FinalK.OZ==undefined)||(ResultK.OZ==FinalK.OZ))
                                &&((FinalK.EM==undefined)||(ResultK.EM==FinalK.EM))
                                &&((FinalK.ZPM==undefined)||(ResultK.ZPM==FinalK.ZPM))
                                &&((FinalK.TZM==undefined)||(ResultK.TZM==FinalK.TZM))
                                &&((FinalK.MT==undefined)||(ResultK.MT==FinalK.MT))
                            ) {
                                if (result.findIndex(item=>(item=='1'))!==-1) smeta_row[smeta_enum.smeta.Mode2020Order]='2024'
                                return result
                            }
                        }
                        
                        return defresult
                    }
                    let Koeffs=GetXMLNodes(itm,'Cost/Coefficients/Coefficient')
                    if (Koeffs.length==0) Koeffs=GetXMLNodes(itm,'Transport/Coefficients/Coefficient')
                    if (Koeffs.length==0) Koeffs=GetXMLNodes(itm,'Material/Coefficients/Coefficient')
                    let FinKoeff=GetXMLNodes(itm,'Cost/Coefficients/Final')
                    if (FinKoeff.length==0) FinKoeff=GetXMLNodes(itm,'Transport/Coefficients/Final')
                    if (FinKoeff.length==0) FinKoeff=GetXMLNodes(itm,'Material/Coefficients/Final')     
                    const KS=GetKoeffs(Koeffs)
                    const FK=GetFinalKoeff(FinKoeff)
                    const AddOn10=GetAddOn10(FK,KS)
                        Koeffs.forEach((k:number,i:number)=>{
                        let k_row =GetRow(smeta_enum,"koeff",inputrows)
                        k_row[smeta_enum.koeff.smeta]=RowIndex(gge_smeta,"smeta")
                        const Values=GetXMLNodes(k,'Values/Value')
                        let Options='Base Curr'
                        Values.forEach((value:any)=>{
                            switch(GetXMLNodeContent(value,"Target")) {
                                case 'ЭМ':  
                                    k_row[smeta_enum.koeff.Value_EM]=GetXMLNodeContent(value,"CoefValue")
                                    Options=`${Options} EmQty`
                                    break
                                case 'ЗТ':  
                                    k_row[smeta_enum.koeff.Value_OZ]=GetXMLNodeContent(value,"CoefValue")
                                    Options=`${Options} OzpTz`
                                    break
                                case 'ЗТМ':  
                                    k_row[smeta_enum.koeff.Value_ZM]=GetXMLNodeContent(value,"CoefValue")
                                    Options=`${Options} ZpmTz`
                                    break
                                case 'ОТ':  
                                    k_row[smeta_enum.koeff.Value_OZ]=GetXMLNodeContent(value,"CoefValue")
                                    break
                                case 'ОТМ':  
                                    k_row[smeta_enum.koeff.Value_ZM]=GetXMLNodeContent(value,"CoefValue")
                                    break
                                case 'МАТ': 
                                    k_row[smeta_enum.koeff.Value_MT]=GetXMLNodeContent(value,"CoefValue")
                                    Options=`${Options} MatQty`
                                    break
                                case 'ОБ':  
                                    k_row[smeta_enum.koeff.Value_MT]=GetXMLNodeContent(value,"CoefValue")
                                    Options=`${Options} MatQty`
                                    break
                                case 'Стоимость':  
                                    k_row[smeta_enum.koeff.Value_PZ]=GetXMLNodeContent(value,"CoefValue")
                                    Options=`${Options} PzAll`
                                    break
                              }
                        })
                       k_row[smeta_enum.koeff.Caption]=GetXMLNodeContent(k,"Name")
                        k_row[smeta_enum.koeff.Code]=GetXMLNodeContent(k,"Reason")
                        k_row[smeta_enum.koeff.Options]=`${Options}${(AddOn10[i]=="1")?' AddOn10':''}` 
                        k_row[smeta_enum.koeff.position]=RowIndex(gge_smeta,"position") 
                        k_row[smeta_enum.koeff.Level]=String(i+1)
                        gge_smeta.koeff.push(k_row)    
                    })
                    
                    const Tzrs=GetXMLNodes(itm,"Cost/ResourcesInternal/Worker")
                    Tzrs.forEach((res:any)=>{
                        if (GetXMLNodeContent(res,'Code')!=='2'){
                            let res_row =GetRow(smeta_enum,"resource",inputrows)
                            res_row[smeta_enum.resource.Id]=RowIndex(gge_smeta,"resource")
                            res_row[smeta_enum.resource.Type]=`Tzr`
                            res_row[smeta_enum.resource.position]=RowIndex(gge_smeta,"position")
                            res_row[smeta_enum.resource.Code]=GetXMLNodeContent(res,'Code')
                            res_row[smeta_enum.resource.Caption]=GetXMLNodeContent(res,'Name')
                            res_row[smeta_enum.resource.Quantity]=GetXMLNodeContent(res,'Consumption')
                            res_row[smeta_enum.resource.Units]=GetXMLNodeContent(res,'Unit')
                            res_row[smeta_enum.resource.WorkClass]=(()=>{
                                const code:string=GetXMLNodeContent(res,'Code')
                                try{
                                    const parts=code.split('-')
                                    if (parts.length==3){
                                        return String(Number(parts[2])/10)
                                    }
                                }catch{}                            
                                return ''
                            })()
                            res_row[smeta_enum.resource.PriceCurrValue]=GetXMLNodeContent(res,'PricePerUnitCur')
                            gge_smeta.resource.push(res_row)
                        }
                        
                    })
                     const Mchs=GetXMLNodes(itm,"Cost/ResourcesInternal/Machine")
                    let tzm:number=0
                    let mchpos:any[]=[]
                    Mchs.forEach((res:any)=>{
                        let res_row =GetRow(smeta_enum,"resource",inputrows)
                        res_row[smeta_enum.resource.Id]=RowIndex(gge_smeta,"resource")
                        res_row[smeta_enum.resource.Type]=`Mch`
                        res_row[smeta_enum.resource.position]=RowIndex(gge_smeta,"position")
                        res_row[smeta_enum.resource.Code]=GetXMLNodeContent(res,'Code')
                        res_row[smeta_enum.resource.Caption]=GetXMLNodeContent(res,'Name')
                        res_row[smeta_enum.resource.Quantity]=GetXMLNodeContent(res,'Consumption')
                        res_row[smeta_enum.resource.Units]=GetXMLNodeContent(res,'Unit')
                        res_row[smeta_enum.resource.PriceBaseValue]=GetXMLNodeContent(res,'PricePerUnitBase')
                        res_row[smeta_enum.resource.PriceCurrValue]=GetXMLNodeContent(res,'PricePerUnitCur')
                        res_row[smeta_enum.resource.TzmWorkClass]=GetXMLNodeContent(res,'Machinist/Grade')
                        res_row[smeta_enum.resource.TzmPrice]=GetXMLNodeContent(res,'Machinist/PricePerUnitCur')
                        const tzme=Number(GetXMLNodeContent(res,'Machinist/Consumption'))/Number(GetXMLNodeContent(res,'Consumption'))
                        if (tzme>0){
                           res_row[smeta_enum.resource.TzmQuantity]=String(tzme) 
                            tzm=tzm+tzme*Number(GetXMLNodeContent(res,'Consumption'))
                        }                        
                        res_row[smeta_enum.resource.index]=
                            (()=>{
                                const Index =GetXMLNodes(res,'Index')[0]
                                let ind =GetRow(smeta_enum,"index",inputrows)
                                ind[smeta_enum.index.smeta]=RowIndex(gge_smeta,"smeta")
                                if (Index){  
                                    res_row[smeta_enum.resource.Options]='ForcedResIdx'
                                    ind[smeta_enum.index.Id]=RowIndex(gge_smeta,"index")
                                    ind[smeta_enum.index.Type]= 'Res'
                                    ind[smeta_enum.index.Caption]= GetXMLNodeContent(Index,'Name')
                                    ind[smeta_enum.index.Code]= GetXMLNodeContent(Index,'Reason')
                                    ind[smeta_enum.index.EM]= GetXMLNodeContent(Index,'Value/IndexValue')                      
                                    let rowindex=gge_smeta.index.findIndex(item=>{
                                    let result=true
                                    for (var i = 0; i < item.length; i++) {
                                        if ((item[i]!==ind[i])&&(i!==smeta_enum.index.Id)) {
                                            result=false
                                            break
                                        }
                                    }
                                    return result
                                    })
                                    if (rowindex<0) {
                                        rowindex=gge_smeta.index.length+1
                                        gge_smeta.index.push(ind)                                    
                                    } else rowindex++
                                    return String(rowindex)
                                }
                                return ''
                            })()

                            mchpos.push(res_row)
                        //tblress.rows.push(res_row)
                    })

                   if (tzm>0){
                        let res_row =GetRow(smeta_enum,"resource",inputrows)
                        res_row[smeta_enum.resource.Id]=RowIndex(gge_smeta,"resource")
                        res_row[smeta_enum.resource.Type]=`Tzm`
                        res_row[smeta_enum.resource.position]=RowIndex(gge_smeta,"position")
                        res_row[smeta_enum.resource.Caption]=`Затраты труда машинистов`
                        res_row[smeta_enum.resource.Code]=`2`
                        res_row[smeta_enum.resource.Units]=`чел.-ч`
                        res_row[smeta_enum.resource.Quantity]=String(tzm)
                        gge_smeta.resource.push(res_row)
                    }
                    mchpos=mchpos.map((item)=>{
                        const row=[...item]
                        row[smeta_enum.resource.Id]=RowIndex(gge_smeta,"resource")
                        return row
                    })
                    gge_smeta.resource.push(...mchpos)
                     const Mats=GetXMLNodes(itm,"Cost/ResourcesInternal/Material")
                    Mats.forEach((res:any)=>{
                        let res_row =GetRow(smeta_enum,"resource",inputrows)
                        res_row[smeta_enum.resource.Id]=RowIndex(gge_smeta,"resource")
                        res_row[smeta_enum.resource.Type]=`Mat`
                        res_row[smeta_enum.resource.position]=RowIndex(gge_smeta,"position")
                        res_row[smeta_enum.resource.Code]=GetXMLNodeContent(res,'Code')
                        res_row[smeta_enum.resource.Options]=(res_row[smeta_enum.resource.Code]=='999-9900')?'Junk':''
                        res_row[smeta_enum.resource.Caption]=GetXMLNodeContent(res,'Name')
                        res_row[smeta_enum.resource.Quantity]=GetXMLNodeContent(res,'Consumption')
                        res_row[smeta_enum.resource.Units]=GetXMLNodeContent(res,'Unit')
                        res_row[smeta_enum.resource.PriceBaseValue]=GetXMLNodeContent(res,'PricePerUnitBase')
                        res_row[smeta_enum.resource.PriceCurrValue]=GetXMLNodeContent(res,'PricePerUnitCur')
                        res_row[smeta_enum.resource.Mass]=GetXMLNodeContent(res,'Transport/Consumption')
                        const Trans=GetXMLNodes(res,"Transport")
                        Trans.forEach((trs:any)=>{
                            let trans_row =GetRow(smeta_enum,"trans",inputrows)
                            trans_row[smeta_enum.trans.resource]=RowIndex(gge_smeta,"resource")
                            trans_row[smeta_enum.trans.Code]=GetXMLNodeContent(trs,'Code')
                            trans_row[smeta_enum.trans.Caption]=GetXMLNodeContent(trs,'Name')
                            trans_row[smeta_enum.trans.PriceCurr]=GetXMLNodeContent(trs,'PricePerUnitCur')
                            trans_row[smeta_enum.trans.Units]=GetXMLNodeContent(trs,'Unit')
                            trans_row[smeta_enum.trans.KC]=GetXMLNodeContent(trs,'Coefficients/Final/Values/Value/CoefValue')
                            gge_smeta.trans.push(trans_row)
                        })
                        res_row[smeta_enum.resource.index]=
                            (()=>{
                                const Index =GetXMLNodes(res,'Index')[0]
                                let ind =GetRow(smeta_enum,"index",inputrows)
                                ind[smeta_enum.index.smeta]=RowIndex(gge_smeta,"smeta")
                                if (Index){  
                                    res_row[smeta_enum.resource.Options]='ForcedResIdx'
                                    ind[smeta_enum.index.Id]=RowIndex(gge_smeta,"index")
                                    ind[smeta_enum.index.Type]= 'Res'
                                    ind[smeta_enum.index.Caption]= GetXMLNodeContent(Index,'Name')
                                    ind[smeta_enum.index.Code]= GetXMLNodeContent(Index,'Reason')
                                    ind[smeta_enum.index.MT]= GetXMLNodeContent(Index,'Value/IndexValue')                       
                                    let rowindex=gge_smeta.index.findIndex(item=>{
                                    let result=true
                                    for (var i = 0; i < item.length; i++) {
                                        if ((item[i]!==ind[i])&&(i!==smeta_enum.index.Id)) {
                                            result=false
                                            break
                                        }
                                    }
                                    return result
                                    })
                                    if (rowindex<0) {
                                        rowindex=gge_smeta.index.length+1
                                        gge_smeta.index.push(ind)                                    
                                    } else rowindex++
                                    return String(rowindex)
                                }
                                return ''
                            })()
                            gge_smeta.resource.push(res_row)
                        })
                        const MatsNotCount=GetXMLNodes(itm,"Cost/Resources/Material")
                        MatsNotCount.forEach((res:any)=>{
                            let res_row =GetRow(smeta_enum,"resource",inputrows)
                            res_row[smeta_enum.resource.Id]=RowIndex(gge_smeta,"resource")
                            res_row[smeta_enum.resource.Type]=`Mat`
                            res_row[smeta_enum.resource.Options]='NotCount'
                            res_row[smeta_enum.resource.position]=RowIndex(gge_smeta,"position")
                            res_row[smeta_enum.resource.Code]=GetXMLNodeContent(res,'Code')
                            res_row[smeta_enum.resource.Caption]=GetXMLNodeContent(res,'Name')
                            res_row[smeta_enum.resource.Quantity]=GetXMLNodeContent(res,'Consumption')
                            res_row[smeta_enum.resource.Units]=GetXMLNodeContent(res,'Unit')
                            res_row[smeta_enum.resource.Mass]=GetXMLNodeContent(res,'Transport/Consumption')
                            const Trans=GetXMLNodes(res,"Transport")
                            
                            Trans.forEach((trs:any)=>{
                                let trans_row =GetRow(smeta_enum,"trans",inputrows)
                                trans_row[smeta_enum.trans.resource]=RowIndex(gge_smeta,"resource")
                                trans_row[smeta_enum.trans.Code]=GetXMLNodeContent(trs,'Code')
                                trans_row[smeta_enum.trans.Caption]=GetXMLNodeContent(trs,'Name')
                                trans_row[smeta_enum.trans.PriceCurr]=GetXMLNodeContent(trs,'PricePerUnitCur')
                                trans_row[smeta_enum.trans.Units]=GetXMLNodeContent(trs,'Unit')
                                trans_row[smeta_enum.trans.KC]=GetXMLNodeContent(trs,'Coefficients/Final/Values/Value/CoefValue')
                                gge_smeta.trans.push(trans_row)
                            })
                            gge_smeta.resource.push(res_row)
                        })
                        const MatsOut=GetXMLNodes(itm,"Resources/Material")
                        MatsOut.forEach((res:any)=>{
                            let res_row =GetRow(smeta_enum,"resource",inputrows)
                            if (GetXMLNodeContent(res,'Unit')=="%"){
                                position_row[smeta_enum.position.AuxMat]=GetXMLNodeContent(res,'Consumption')
                                position_row[smeta_enum.position.AuxMatCode]=GetXMLNodeContent(res,'Code')
                            }
                            res_row[smeta_enum.resource.Id]=RowIndex(gge_smeta,"resource")
                            res_row[smeta_enum.resource.Type]=`Mat`
                            res_row[smeta_enum.resource.position]=RowIndex(gge_smeta,"position")
                            res_row[smeta_enum.resource.Code]=GetXMLNodeContent(res,'Code')
                            res_row[smeta_enum.resource.Caption]=GetXMLNodeContent(res,'Name')
                            res_row[smeta_enum.resource.Quantity]=GetXMLNodeContent(res,'Consumption')
                            res_row[smeta_enum.resource.Units]=GetXMLNodeContent(res,'Unit')
                            res_row[smeta_enum.resource.PriceBaseValue]=GetXMLNodeContent(res,'PricePerUnitBase')
                            res_row[smeta_enum.resource.PriceCurrValue]=GetXMLNodeContent(res,'PricePerUnitCur')
                            res_row[smeta_enum.resource.Mass]=GetXMLNodeContent(res,'Transport/Consumption')
                            const Trans=GetXMLNodes(res,"Transport")
                            Trans.forEach((trs:any)=>{
                                let trans_row =GetRow(smeta_enum,"trans",inputrows)
                                trans_row[smeta_enum.trans.resource]=RowIndex(gge_smeta,"resource")
                                trans_row[smeta_enum.trans.Code]=GetXMLNodeContent(trs,'Code')
                                trans_row[smeta_enum.trans.Caption]=GetXMLNodeContent(trs,'Name')
                                trans_row[smeta_enum.trans.PriceCurr]=GetXMLNodeContent(trs,'PricePerUnitCur')
                                trans_row[smeta_enum.trans.Units]=GetXMLNodeContent(trs,'Unit')
                                trans_row[smeta_enum.trans.KC]=GetXMLNodeContent(trs,'Coefficients/Final/Values/Value/CoefValue')
                                gge_smeta.trans.push(trans_row)
                            })
                            res_row[smeta_enum.resource.index]=
                                (()=>{
                                    const Index =GetXMLNodes(res,'Index')[0]
                                    let ind =GetRow(smeta_enum,"index",inputrows)
                                    ind[smeta_enum.index.smeta]=RowIndex(gge_smeta,"smeta")
                                    if (Index){  
                                        res_row[smeta_enum.resource.Options]='ForcedResIdx'
                                        ind[smeta_enum.index.Id]=RowIndex(gge_smeta,"index")
                                        ind[smeta_enum.index.Type]= 'Res'
                                        ind[smeta_enum.index.Caption]= GetXMLNodeContent(Index,'Name')
                                        ind[smeta_enum.index.Code]= GetXMLNodeContent(Index,'Reason')
                                        ind[smeta_enum.index.MT]= GetXMLNodeContent(Index,'Value/IndexValue')                       
                                        let rowindex=gge_smeta.index.findIndex(item=>{
                                        let result=true
                                        for (var i = 0; i < item.length; i++) {
                                            if ((item[i]!==ind[i])&&(i!==smeta_enum.index.Id)) {
                                                result=false
                                                break
                                            }
                                        }
                                        return result
                                        })
                                        if (rowindex<0) {
                                            rowindex=gge_smeta.index.length+1
                                            gge_smeta.index.push(ind)                                    
                                        } else rowindex++
                                        return String(rowindex)
                                    }
                                    return ''
                                })()
                            gge_smeta.resource.push(res_row)
                        })
                 gge_smeta.position.push(position_row)     
                })
                gge_smeta.chapter.push(chapter_row)
                })
                gge_smeta.smeta.push(smeta_row)


        }


        const parser = new DOMParser();
        if (window.DOMParser){
            if (window.DOMParser)
            {
                const xml = parser.parseFromString(source, "text/xml");
                CreateSmeta(xml.getElementsByTagName("Construction")[0])
            }
            
        }

    }catch{
        return undefined
    }
    return gge_smeta
    
  }

