/* eslint-disable eqeqeq */
import React, { useState } from "react"
import { smeta_enum } from "../types/smeta_tables";
import { SetValue } from "./UtilsTbls";
import { Dropdown, IDropdownOption, IDropdownStyles, IStackItemStyles, IStackProps, Stack } from "@fluentui/react";
const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300}
};
const stackTokens = { };
const columnProps: Partial<IStackProps> = {
  tokens: {  },
};
const stackItemStyles: IStackItemStyles = {
  root: {

  },
};
let lz_table:any[][]
export const WithNDSSimple = props => {
  const items=[
    { key: 'NoNDS', text: 'Без НДС' },
    { key: 'NDS20', text: 'НДС 20%' },
    { key: 'CompensNDS', text: 'Компенсация по УСН' },
    { key: 'CompensNDS2', text: 'Компенсация по УСН2' },
  ]
  const [Checked,  toggleChecked ] = useState(()=>{
    
    try{
      if (props.source) lz_table=props.source['lz']
      let check=(lz_table.findIndex(item=>(String(item[smeta_enum.lz.Caption]).includes("НДС")&&String(item[smeta_enum.lz.Value])=="20"))>-1)
      if (check)return'NDS20'
      check=(lz_table.findIndex(item=>(String(item[smeta_enum.lz.Caption]).includes("НДС")&&String(item[smeta_enum.lz.Formula])).length>0)>-1)
      if (check)return'CompensNDS'
      return 'NoNDS'
    }
    catch{
      
    }
    return false
  });
  const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>(()=>{
    
    try{
      if (props.source) lz_table=props.source['lz']
      let check=(lz_table.findIndex(item=>(String(item[smeta_enum.lz.Caption]).includes("НДС")&&String(item[smeta_enum.lz.Value])=="20"))>-1)
      if (check)return items[1]
      check=(lz_table.findIndex(item=>(String(item[smeta_enum.lz.Caption]).includes("НДС")&&String(item[smeta_enum.lz.Formula]))=="(МАТ+ЭМ+О)*0,2")>-1)
      if (check)return items[3]
      check=(lz_table.findIndex(item=>(String(item[smeta_enum.lz.Caption]).includes("НДС")&&String(item[smeta_enum.lz.Formula])).length>0)>-1)
      if (check)return items[2]
      return items[0]
    }
    catch{
      
    }
    return items[0]
  });


  const OnCheck= (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
    let rows=['']
    let tmpl_lz_row:any[] =rows
    try{
      if (item.key=="NDS20"){
        let lz_row =[...tmpl_lz_row]
        SetValue(lz_row,smeta_enum.lz.Glava,"15")
        SetValue(lz_row,smeta_enum.lz.Caption,"НДС")
        SetValue(lz_row,smeta_enum.lz.Value,"20")
        SetValue(lz_row,smeta_enum.lz.Level,"2")
        SetValue(lz_row,smeta_enum.lz.Options,"Percent")
        SetValue(lz_row,smeta_enum.lz.smeta,"1")
        lz_table.push(lz_row)
        setSelectedItem(items[1])
      }else if (item.key=="CompensNDS"){
        let lz_row =[...tmpl_lz_row]
        SetValue(lz_row,smeta_enum.lz.Glava,"15")
        SetValue(lz_row,smeta_enum.lz.Caption,"Компенсация НДС")
        SetValue(lz_row,smeta_enum.lz.Formula,"(МАТ+(ЭМ-ЗПМ)+НР*0,1712+СП*0,15+О)*0,2")
        SetValue(lz_row,smeta_enum.lz.Level,"2")
        SetValue(lz_row,smeta_enum.lz.Options,"Percent HideZero")
        SetValue(lz_row,smeta_enum.lz.smeta,"1")
        lz_table.push(lz_row)
        setSelectedItem(items[2])
      }else if (item.key=="CompensNDS2"){
        let lz_row =[...tmpl_lz_row]
        SetValue(lz_row,smeta_enum.lz.Glava,"15")
        SetValue(lz_row,smeta_enum.lz.Caption,"Компенсация НДС")
        SetValue(lz_row,smeta_enum.lz.Formula,"(МАТ+ЭМ+О)*0,2")
        SetValue(lz_row,smeta_enum.lz.Level,"2")
        SetValue(lz_row,smeta_enum.lz.Options,"Percent HideZero")
        SetValue(lz_row,smeta_enum.lz.smeta,"1")
        lz_table.push(lz_row)
        setSelectedItem(items[3])
      }else{
        const ind= lz_table.findIndex(item=>(String(item[smeta_enum.lz.Caption]).includes("НДС")))
       if (ind>-1){
        lz_table.splice(ind)
        setSelectedItem(items[0])
       }
      }
    }
    catch{

    }
  }
  //IsSmetaDoc()
    return (
        <>

         <div >
          <br/>
            <Stack   tokens={stackTokens}  {...columnProps}>
              <Stack.Item align="center" styles={stackItemStyles}>
                <Dropdown
                  placeholder="Без НДС"
                  label="Учесть НДС"
                  selectedKey={Checked ? selectedItem.key : undefined}
                  options={items}
                  styles={dropdownStyles}
                  onChange={OnCheck}
                />
                </Stack.Item>
               
               </Stack>
            
         </div>
        </>
        
      );
    }


