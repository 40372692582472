import { IDatePickerStrings } from "@fluentui/react/lib/components/DatePicker/DatePicker.types"

export interface IInputRow {
    id?: string
    name?: string
    header?:any[]
    row?:any[]

  }
  export interface IInputTable {
    name: string
    rows:any[][]
  }
 /* export interface IInputSmeta {
    filename:string
    p: any[][]
    0: any[][]
    1: any[][]
    2: any[][]
    3: any[][]
    4: any[][]
    vr: any[][]
    i: any[][]
    k: any[][]
    lz: any[][]
    wl: any[][]
  }*/
export interface IInputRow {
    id?: string
    name?: string
    header?:any[]
    row?:any[]

    }
export interface IInputSmeta {
    smeta: any[][]
    chapter: any[][]
    position: any[][]
    resource: any[][]
    trans: any[][]
    vr: any[][]
    index: any[][]
    koeff: any[][]
    lz: any[][]
    wl: any[][]
    }
export const region_enum=[
    {Code:1, Name:'Республика Адыгея (Адыгея)'},
    {Code:2, Name:'Республика Башкортостан'},
    {Code:3, Name:'Республика Бурятия'},
    {Code:4, Name:'Республика Алтай'},
    {Code:5, Name:'Республика Дагестан'},
    {Code:6, Name:'Республика Ингушетия'},
    {Code:7, Name:'Кабардино-Балкарская Республика'},
    {Code:8, Name:'Республика Калмыкия'},
    {Code:9, Name:'Карачаево-Черкесская Республика'},
    {Code:10, Name:'Республика Карелия'},
    {Code:11, Name:'Республика Коми'},
    {Code:12, Name:'Республика Марий Эл'},
    {Code:13, Name:'Республика Мордовия'},
    {Code:14, Name:'Республика Саха (Якутия)'},
    {Code:15, Name:'Республика Северная Осетия - Алания'},
    {Code:16, Name:'Республика Татарстан (Татарстан)'},
    {Code:17, Name:'Республика Тыва'},
    {Code:18, Name:'Удмуртская Республика'},
    {Code:19, Name:'Республика Хакасия'},
    {Code:20, Name:'Чеченская Республика'},
    {Code:21, Name:'Чувашская Республика - Чувашия'},
    {Code:22, Name:'Алтайский край'},
    {Code:23, Name:'Краснодарский край'},
    {Code:24, Name:'Красноярский край'},
    {Code:25, Name:'Приморский край'},
    {Code:26, Name:'Ставропольский край'},
    {Code:27, Name:'Хабаровский край'},
    {Code:28, Name:'Амурская область'},
    {Code:29, Name:'Архангельская область'},
    {Code:30, Name:'Астраханская область'},
    {Code:31, Name:'Белгородская область'},
    {Code:32, Name:'Брянская область'},
    {Code:33, Name:'Владимирская область'},
    {Code:34, Name:'Волгоградская область'},
    {Code:35, Name:'Вологодская область'},
    {Code:36, Name:'Воронежская область'},
    {Code:37, Name:'Ивановская область'},
    {Code:38, Name:'Иркутская область'},
    {Code:39, Name:'Калининградская область'},
    {Code:40, Name:'Калужская область'},
    {Code:41, Name:'Камчатский край'},
    {Code:42, Name:'Кемеровская область - Кузбасс'},
    {Code:43, Name:'Кировская область'},
    {Code:44, Name:'Костромская область'},
    {Code:45, Name:'Курганская область'},
    {Code:46, Name:'Курская область'},
    {Code:47, Name:'Ленинградская область'},
    {Code:48, Name:'Липецкая область'},
    {Code:49, Name:'Магаданская область'},
    {Code:50, Name:'Московская область'},
    {Code:51, Name:'Мурманская область'},
    {Code:52, Name:'Нижегородская область'},
    {Code:53, Name:'Новгородская область'},
    {Code:54, Name:'Новосибирская область'},
    {Code:55, Name:'Омская область'},
    {Code:56, Name:'Оренбургская область'},
    {Code:57, Name:'Орловская область'},
    {Code:58, Name:'Пензенская область'},
    {Code:59, Name:'Пермский край'},
    {Code:60, Name:'Псковская область'},
    {Code:61, Name:'Ростовская область'},
    {Code:62, Name:'Рязанская область'},
    {Code:63, Name:'Самарская область'},
    {Code:64, Name:'Саратовская область'},
    {Code:65, Name:'Сахалинская область'},
    {Code:66, Name:'Свердловская область'},
    {Code:67, Name:'Смоленская область'},
    {Code:68, Name:'Тамбовская область'},
    {Code:69, Name:'Тверская область'},
    {Code:70, Name:'Томская область'},
    {Code:71, Name:'Тульская область'},
    {Code:72, Name:'Тюменская область'},
    {Code:73, Name:'Ульяновская область'},
    {Code:74, Name:'Челябинская область'},
    {Code:75, Name:'Забайкальский край'},
    {Code:76, Name:'Ярославская область'},
    {Code:77, Name:'город федерального значения Москва'},
    {Code:78, Name:'город федерального значения Санкт-Петербург'},
    {Code:79, Name:'Еврейская автономная область'},
    {Code:83, Name:'Ненецкий автономный округ'},
    {Code:86, Name:'Ханты-Мансийский автономный округ - Югра'},
    {Code:87, Name:'Чукотский автономный округ'},
    {Code:89, Name:'Ямало-Ненецкий автономный округ'},
    {Code:90, Name:'Запорожская область'},
    {Code:91, Name:'Республика Крым'},
    {Code:92, Name:'город федерального значения Севастополь'},
    {Code:93, Name:'Донецкая Народная Республика'},
    {Code:94, Name:'Луганская Народная Республика'},
    {Code:95, Name:'Херсонская область'},
    {Code:99, Name:'Иные территории,включая город и космодром Байконур'},
    ]   
export const props_row_enum={
    ProgramVersion:0,
    Generator:1,
    LocNum:2,
    Description:3,
    RegionName:4,
    RegionID:5,
    Zone01Name:6,
    Zone01ID:7,
    AdmRegionCode:8,
    Overhd_BaseName:9,
    Overhd_RegNumber:10,
    Overhd_RegDate:11,
    Overhd_OrderDetails:12,
    Profit_BaseName:13,
    Profit_RegNumber:14,
    Profit_RegDate:15,
    Profit_OrderDetails:16,
    CatFile:17,
    NrspFile:18,
    IndexesMode:19,
    IndexesLinkMode:20,
    CommonNK_CommonK:21,
    CommonNK_MethodKB:22,
    CommonNK_MethodKI:23,
    CommonNK_MethodKR:24,
    CommonNK_ActiveItems:25,
    CommonPK_CommonK:26,
    CommonPK_MethodKB:27,
    CommonPK_MethodKI:28,
    CommonPK_MethodKR:29,
    CommonPK_ActiveItems:30,
    ComposeFIO:31,
    VerifyFIO:32,
    RegNumber:33,
    RegDate:34,
    OrderDetails:35,
    BaseName:36,
    BaseType:37,
    Overhd_BaseType:38,
    Profit_BaseType:39,
    
} 
export const props_col_enum={
    Id:0,
    Type:1,
    Group:2,
    Caption:3,
    Value:4
} 
export const vrs_col_enum={
    Grop_Caption:0,
    Group_ID:1,
    Caption:2,
    ID:3,
    Category:4,
    NrCode:5,
    SpCode:6,
    Nacl:7,
    Plan:8,
    NaclCurr:9,
    PlanCurr:10,
    NaclMask:11,
    PlanMask:12,
    OsColumn:13,
    Row:14,
    NKB:15,
    PNB:16,
    NKI:17,
    PKI:18,
    NKR:19,
    PKR:20,
}
export const chapters_col_enum={
    Caption:0,
    Row:1,
} 
export const positions_col_enum={
    Chapter:0,
    Number:1,
    Code:2,
    Caption:3,
    Units:4,
    PriceLevel:5,
    DBComment:6,
    Vr2001:7,
    Quantity:8,
    Row:9,
    Options:10,
    IndexCode:11,
    BOZ:12,
    BEM:13,
    BZM:14,
    BMT:15,
    COZ:16,
    CEM:17,
    CZM:18,
    CMT:19,
    SlaveRow:20,
    AuxMatCode:21,
    AuxMat:22,
    NKB:23,
    PNB:24,
    NKI:25,
    PKI:26,
    NKR:27,
    PKR:28,
}
export const resources_col_enum={
    Type:0,
    Position:1,
    Caption:2,
    Code:3,
    Units:4,
    Quantity:5,
    WorkClass:6,
    PriceBaseValue:7,
    PriceBaseZM:8,
    PriceCurrValue:9,
    PriceCurrZM:10,
    TzmWorkClass:11,
    TzmQuantity:12,
    TzmPrice:13,
    Options:14,
    IndexCode:15,
    Attribs:16,
    Row:17,
    Mass:18
}
export const indexes_col_enum={
    Type:0,
    Caption:1,
    Code:2,
    OZ:3,
    EM:4,
    ZM:5,
    MT:6,
    Row:7
}
export const k_col_enum={
    Position:0,
    Caption:1,
    Code:2,
    Options:3,
    Value_OZ:4,
    Value_EM:5,
    Value_ZM:6,
    Value_MT:7,
    Level:8
}
export const wls_col_enum={
    Position:0,
    Caption:1,
}
export const lzs_col_enum={
    Glava:0,
    Caption:1,
    Value:2,
    Options:3,
    Level:4,
    Formula:5
}
export const trans_col_enum={
    Resource:0,
    Caption:1,
    Code:2,
    Units:3,
    PriceCurr:4,
    KC:5
}
export interface IDownloadFile {
    xml?: string
    filename?: string
  }

  export const RusDatePickerStrings:IDatePickerStrings={
   goToToday:'Сегодня',
   months:[
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
   ],
   shortMonths:[
    'Янв',
    'Фев',
    'Мар',
    'Апр',
    'Май',
    'Июн',
    'Июл',
    'Авг',
    'Сен',
    'Окт',
    'Ноя',
    'Дек'
   ],
   days:[
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
    'Воскресенье',
   ],
   shortDays:[
    'Вс',
    'Пн',
    'Вт',
    'Ср',
    'Чт',
    'Пт',
    'Сб',
    
   ]
}
export const smeta_enum={
    vr:{
        Grop_Caption:0,
        Group_ID:1,
        Caption:2,
        SourceID:3,
        Category:4,
        NrCode:5,
        SpCode:6,
        Nacl:7,
        Plan:8,
        NaclCurr:9,
        PlanCurr:10,
        NaclMask:11,
        PlanMask:12,
        OsColumn:13,
        Id:14,
        NKB:15,
        PNB:16,
        NKI:17,
        PKI:18,
        NKR:19,
        PKR:20,
        smeta:21,
        ResGroup:22,
        NrKfsCode:23,
        SpKfsCode:24
    },
    smeta:{
      ProgramVersion:0,
      Generator:1,
      LocNum:2,
      Description:3,
      RegionName:4,
      RegionID:5,
      Zone01Name:6,
      Zone01ID:7,
      AdmRegionCode:8,
      Overhd_BaseName:9,
      Overhd_RegNumber:10,
      Overhd_RegDate:11,
      Overhd_OrderDetails:12,
      Profit_BaseName:13,
      Profit_RegNumber:14,
      Profit_RegDate:15,
      Profit_OrderDetails:16,
      CatFile:17,
      NrspFile:18,
      IndexesMode:19,
      IndexesLinkMode:20,
      CommonNK_CommonK:21,
      CommonNK_MethodKB:22,
      CommonNK_MethodKI:23,
      CommonNK_MethodKR:24,
      CommonNK_ActiveItems:25,
      CommonPK_CommonK:26,
      CommonPK_MethodKB:27,
      CommonPK_MethodKI:28,
      CommonPK_MethodKR:29,
      CommonPK_ActiveItems:30,
      ComposeFIO:31,
      VerifyFIO:32,
      RegNumber:33,
      RegDate:34,
      OrderDetails:35,
      BaseName:36,
      BaseType:37,
      Overhd_BaseType:38,
      Profit_BaseType:39,
      FileName:40,
      Id:41,
      Itog:42,
      ItogLZ:43,
      PZ:44,
      OZP:45,
      EMM:46,
      ZPM:47,
      MAT:48,
      Trans:49,
      MATCurr:50,
      FOT:51,
      NR:52,
      SP:53,
      TZR:54,
      TZM:55,
      SItog:56,
      SOZP:57,
      SEMM:58,
      SZPM:59,
      SMAT:60,
      SNR:61,
      SSP:62,
      SMATTrans:63,
      MItog:64,
      MOZP:65,
      MEMM:66,
      MZPM:67,
      MMAT:68,
      MNR:69,
      MSP:70,
      MMATTrans:71,
      PItog:72,
      POZP:73,
      PEMM:74,
      PZPM:75,
      PMAT:76,
      PNR:77,
      PSP:78,
      PMATTrans:79,
      O:80,
      ItogPlus:81,
      ItogLZPlus:82,
      PZPlus:83,
      OZPPlus:84,
      EMMPlus:85,
      ZPMPlus:86,
      MATPlus:87,
      TransPlus:88,
      MATCurrPlus:89,
      FOTPlus:90,
      NRPlus:91,
      SPPlus:92,
      TZRPlus:93,
      TZMPlus:94,
      SItogPlus:95,
      SOZPPlus:96,
      SEMMPlus:97,
      SZPMPlus:98,
      SMATPlus:99,
      SNRPlus:100,
      SSPPlus:101,
      SMATTransPlus:102,
      MItogPlus:103,
      MOZPPlus:104,
      MEMMPlus:105,
      MZPMPlus:106,
      MMATPlus:107,
      MNRPlus:108,
      MSPPlus:109,
      MMATTransPlus:110,
      PItogPlus:111,
      POZPPlus:112,
      PEMMPlus:113,
      PZPMPlus:114,
      PMATPlus:115,
      PNRPlus:116,
      PSPPlus:117,
      PMATTransPlus:118,
      OPlus:119,
      ItogMinus:120,
      ItogLZMinus:121,
      PZMinus:122,
      OZPMinus:123,
      EMMMinus:124,
      ZPMMinus:125,
      MATMinus:126,
      TransMinus:127,
      MATCurrMinus:128,
      FOTMinus:129,
      NRMinus:130,
      SPMinus:131,
      TZRMinus:132,
      TZMMinus:133,
      SItogMinus:134,
      SOZPMinus:135,
      SEMMMinus:136,
      SZPMMinus:137,
      SMATMinus:138,
      SNRMinus:139,
      SSPMinus:140,
      SMATTransMinus:141,
      MItogMinus:142,
      MOZPMinus:143,
      MEMMMinus:144,
      MZPMMinus:145,
      MMATMinus:146,
      MNRMinus:147,
      MSPMinus:148,
      MMATTransMinus:149,
      PItogMinus:150,
      POZPMinus:151,
      PEMMMinus:152,
      PZPMMinus:153,
      PMATMinus:154,
      PNRMinus:155,
      PSPMinus:156,
      PMATTransMinus:157,
      OMinus:158,
      AdmRegionName:159,
      AdmRegionZone:160,
      Index_Info_OrderDetails:161,
      Wage_Info_OrderDetails:162,
      Object:163, //Объект
      Constr:164,
      Reason:165,
      CurrPriceDate:166, //Текущие цены на дату
      Options:167,
      Mode2020Order:168,
      BasePrices:169,
      BaseCalcVrs:170,
      TzDigits:171,
      BlockRoundMode:172,
      MultKPosCalcMode:173,
      TempZone:174,
      TsnTempZone:175,
      MatDigits:176,
      MatRoundMode:177,
      PosKDigits:178,
      ItogOptions:179,
      FirstItogItem:180,
      ItogExpandTo:181,
      PropsConfigName:182,
      Numbering_Mode:183,
      Numbering_Options:184,
      PropsConfigNameModified:185,
      OSChapter:186,
      LinkType:187,
      Industrial:188,
      Cons:189,
      Rec:190,
      Road:191,
      BasePriceDate:192,
      DocDatesOptions:193,
      Index_Info_BaseType:194,
      Wage_Info_BaseType:195,
      KfsFiles:196,
    }, 
    chapter:{
        Caption:0,
        Id:1,
        smeta:2,
        Itog:3,
        PZ:4,
        OZP:5,
        EMM:6,
        ZPM:7,
        MAT:8,
        Trans:9,
        MATTrans:10,
        MATCurr:11,
        FOT:12,
        NR:13,
        SP:14,
        TZR:15,
        TZM:16,
        SItog:17,
        SOZP:18,
        SEMM:19,
        SZPM:20,
        SMAT:21,
        SNR:22,
        SSP:23,
        SMATTrans:24,
        MItog:25,
        MOZP:26,
        MEMM:27,
        MZPM:28,
        MMAT:29,
        MNR:30,
        MSP:31,
        MMATTrans:32,
        PItog:33,
        POZP:34,
        PEMM:35,
        PZPM:36,
        PMAT:37,
        PNR:38,
        PSP:39,
        PMATTrans:40,
        O:41,
        ItogPlus:42,
        PZPlus:43,
        OZPPlus:44,
        EMMPlus:45,
        ZPMPlus:46,
        MATPlus:47,
        TransPlus:48,
        MATTransPlus:49,
        MATCurrPlus:50,
        FOTPlus:51,
        NRPlus:52,
        SPPlus:53,
        TZRPlus:54,
        TZMPlus:55,
        SItogPlus:56,
        SOZPPlus:57,
        SEMMPlus:58,
        SZPMPlus:59,
        SMATPlus:60,
        SNRPlus:61,
        SSPPlus:62,
        SMATTransPlus:63,
        MItogPlus:64,
        MOZPPlus:65,
        MEMMPlus:66,
        MZPMPlus:67,
        MMATPlus:68,
        MNRPlus:69,
        MSPPlus:70,
        MMATTransPlus:71,
        PItogPlus:72,
        POZPPlus:73,
        PEMMPlus:74,
        PZPMPlus:75,
        PMATPlus:76,
        PNRPlus:77,
        PSPPlus:78,
        PMATTransPlus:79,
        OPlus:80,
        ItogMinus:81,
        PZMinus:82,
        OZPMinus:83,
        EMMMinus:84,
        ZPMMinus:85,
        MATMinus:86,
        TransMinus:87,
        MATTransMinus:88,
        MATCurrMinus:89,
        FOTMinus:90,
        NRMinus:91,
        SPMinus:92,
        TZRMinus:93,
        TZMMinus:94,
        SItogMinus:95,
        SOZPMinus:96,
        SEMMMinus:97,
        SZPMMinus:98,
        SMATMinus:99,
        SNRMinus:100,
        SSPMinus:101,
        SMATTransMinus:102,
        MItogMinus:103,
        MOZPMinus:104,
        MEMMMinus:105,
        MZPMMinus:106,
        MMATMinus:107,
        MNRMinus:108,
        MSPMinus:109,
        MMATTransMinus:110,
        PItogMinus:111,
        POZPMinus:112,
        PEMMMinus:113,
        PZPMMinus:114,
        PMATMinus:115,
        PNRMinus:116,
        PSPMinus:117,
        PMATTransMinus:118,
        OMinus:119,
        QuantityPlus:120, //Количество плюс сумма всех количеств позиций. Нужно для отображения разделов в смете ВОРах +
        QuantityMinus:121,//Количество минус сумма всех количеств позиций. Нужно для отображения разделов в смете ВОРах -
        CountInSmeta:122,	//Сколько раз название встречается в смете
        SearchKey:123, //Составной ключ для поиска
        AuxMatSum:124,//Ненормированные материалы(обычно это 2% от ОЗП для монтажных работ)
        AuxMatSumPlus:125,	
        AuxMatSumMinus:126,
        Number:127

        

        

    }, 
    position:{
        chapter:0,
        Number:1,
        Code:2,
        Caption:3,
        Units:4,
        PriceLevel:5,
        DBComment:6,
        vr:7,
        Quantity:8,
        Id:9,
        Options:10,
        index:11,
        BOZ:12,
        BEM:13,
        BZM:14,
        BMT:15,
        COZ:16,
        CEM:17,
        CZM:18,
        CMT:19,
        SlaveRow:20,
        AuxMatCode:21,
        AuxMat:22,
        NKB:23,
        PNB:24,
        NKI:25,
        PKI:26,
        NKR:27,
        PKR:28,
        NR:29,
        SP:30,
        NrCode:31,	
        SpCode:32,	
        VRCaption:33,	
        IOZP:34,	
        ITZR:35,
        IZPM:36,	
        IEMM:37,	
        ITZM:38,
        IMAT:39,	
        ITrans:40,	
        IMATTrans:41,	
        IPZ:42,	
        IFOT:43,	
        INR:44,	
        ISP:45,	
        IVSEGO:46,
        EOZP:47,	
        EEMM:48,	
        EZPM:49,	
        ETZM:50,	
        EMAT:51,	
        EPZ:52,	
        EVSEGO:53,
        VR_Group:54,
        EMATCurr:55,
        IMATCurr:56,
        EMATBase:57,
        ICurrIndex:58,
        ChapterCaption:59,	
        SearchKey:60,	
        CountInChapter:61,
        CompareQuantity:62,
        QuantityPlus:63,
        QuantityMinus:64,
        IOZPPlus:65,
        ITZRPlus:66,
        IZPMPlus:67,
        IEMMPlus:68,
        ITZMPlus:69,
        IMATPlus:70,
        ITransPlus:71,
        IMATTransPlus:72,
        IPZPlus:73,
        IFOTPlus:74,
        INRPlus:75,
        ISPPlus:76,
        IVSEGOPlus:77,
        IOZPMinus:78,
        ITZRMinus:79,
        IZPMMinus:80,
        IEMMMinus	:81,
        ITZMMinus:82,
        IMATMinus:83,
        ITransMinus:84,
        IMATTransMinus:85,
        IPZMinus:86,
        IFOTMinus:87,
        INRMinus:88,
        ISPMinus:89,
        IVSEGOMinus:90,      
        EMATPlus:91,      
        EMATMinus:92,      
        IMATCurrPlus:93,  	
        IMATCurrMinus:94,  
        CompareSmeta:95,
        CompareNumberIn:96,
        CompareNumberOut:97,
        Kunits:98,	
        NaturalUnits:99,
        UserComment1:100,
        UserComment2:101,
        BPZ:102,
        Precision:103,
        AuxMatSum:104,//Ненормированные материалы(обычно это 2% от ОЗП для монтажных работ)
        AuxMatSumPlus:105,	
        AuxMatSumMinus:106,
        NRK:107, //Процент накладных с коэффициентом
        SPK:108, //Процент сметной прибыли с коэффициентом
        NKRVR:109,//коэффициент накладных
        PKRVR:110,//коэффициент сметной
        smeta:111, //Id Сметы 
        EFOT:112, //Id Сметы 
        ENR:113,
        ESP:114
    },
    resource:{
        Type:0,
        position:1,
        Caption:2,
        Code:3,
        Units:4,
        Quantity:5,
        WorkClass:6,
        PriceBaseValue:7,
        PriceBaseZM:8,
        PriceCurrValue:9,
        PriceCurrZM:10,
        TzmWorkClass:11,
        TzmQuantity:12,
        TzmPrice:13,
        Options:14,
        index:15,
        Attribs:16,
        Id:17,
        Mass:18,
        ITZR:19,
        PosQuantity:20,
      	IndexValue:21,	
        IOZP:22,
        IEMM:23, 
        IZPM:24, 
        ITZM:25, 
        IMAT:26,
        ETZR:27,	
        EOZP:28,	
        EEMM:29,	
        EZPM:30,	
        ETZM:31,	
        EMAT:32,	
        PEREVOZ:33,	
        KUnits:34,
        PosQuantityPlus:35,
        PosQuantityMinus:36,
        ITZRPlus:37,
        IOZPPlus:38,
        IEMMPlus:39,
        IZPMPlus:40,
        ITZMPlus:41,
        IMATPlus:42,
        PEREVOZPlus:43,
        ITZRMinus:44,
        IOZPMinus:45,
        IEMMMinus:46,
        IZPMMinus:47,
        ITZMMinus:48,
        IMATMinus:49,
        PEREVOZMinus:50,
        Koeff:51,	
        KoeffTZM:52,
        AuxMatSum:53,//Ненормированные материалы(обычно это 2% от ОЗП для монтажных работ)
        AuxMatSumPlus:54,	
        AuxMatSumMinus:55,
        KoeffCurr:56, //Коэффициент назначемый только на стоимость(без ЗПМ)	
        KoeffCurrZM:57, //Коэффициент назначемый только на стоимость ЗПМ
        smeta:58, //Id Сметы 

    },
    index:{
        Type:0,
        Caption:1,
        Code:2,
        OZ:3,
        EM:4,
        ZM:5,
        MT:6,
        Id:7,
        smeta:8,
        SMR:9
    },
    koeff:{
        position:0,
        Caption:1,
        Code:2,
        Options:3,
        Value_OZ:4,
        Value_EM:5,
        Value_ZM:6,
        Value_MT:7,
        Level:8,
        smeta:9,
        Id:10,
        Value_PZ:11,
        View_Caption:12 // Текстовое отображение коэффициента в выходной форме
    },
    wl:{
        position:0,
        Caption:1,
    },
    lz:{
        Glava:0,
        Caption:1,
        Value:2,
        Options:3,
        Level:4,
        Formula:5,
        smeta:6
    },
    trans:{
        resource:0,
        Caption:1,
        Code:2,
        Units:3,
        PriceCurr:4,
        KC:5,
        Itog:6,
        ItogPlus:7,
        ItogMinus:8,
        position:9,//Позиция сметы. 
        Number:10, //Номер в выходной форме
        Quantity:11,//Количество груза
        QuantityPlus:12,//Количество груза плюс
        QuantityMinus:13//Количество груза минус

    }
}